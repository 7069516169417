import { createSlice } from '@reduxjs/toolkit'

import {
  ECheckoutStep,
  EPaymentMethodName,
  ISpreeOrder,
  ISpreeOrderLineItem,
  IStoreState,
  TCheckoutStep,
  TPaymentMethodName
} from '@/types'

export interface ICheckoutState {
  activeStep: TCheckoutStep
  applianceSpreeOrder: ISpreeOrder | null
  mealSpreeOrder: ISpreeOrder | null
  paymentMethod: TPaymentMethodName
  changedLineItems: ISpreeOrderLineItem[]
  isSubmitButtonDisabled: boolean
  isRedirecting: boolean
  lowestMonthlyPriceAffirm: number
}

const initialState: ICheckoutState = {
  activeStep: ECheckoutStep.ADDONS,
  applianceSpreeOrder: null,
  mealSpreeOrder: null,
  paymentMethod: EPaymentMethodName.CREDIT_CARD,
  changedLineItems: [],
  isSubmitButtonDisabled: true,
  isRedirecting: false,
  lowestMonthlyPriceAffirm: null
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setActiveStep: (
      state: ICheckoutState,
      action: { payload: TCheckoutStep }
    ) => {
      state.activeStep = action.payload
    },
    setApplianceSpreeOrder: (
      state: ICheckoutState,
      action: { payload: ISpreeOrder }
    ) => {
      state.applianceSpreeOrder = action.payload
      state.changedLineItems = []
    },
    setMealSpreeOrder: (
      state: ICheckoutState,
      action: { payload: ISpreeOrder }
    ) => {
      state.mealSpreeOrder = action.payload
    },
    setPaymentMethod: (
      state: ICheckoutState,
      action: { payload: TPaymentMethodName }
    ) => {
      state.paymentMethod = action.payload
    },
    setIsSubmitButtonDisabled: (
      state: ICheckoutState,
      action: { payload: boolean }
    ) => {
      state.isSubmitButtonDisabled = action.payload
    },
    setIsRedirecting: (state: ICheckoutState, action: { payload: boolean }) => {
      state.isRedirecting = action.payload
    },
    setLowestMonthlyPriceAffirm: (state, action: { payload: number }) => {
      state.lowestMonthlyPriceAffirm = action.payload
    },
    addLineItem: (
      state: ICheckoutState,
      action: { payload: ISpreeOrderLineItem }
    ) => {
      const current = state.changedLineItems?.find(
        (item) => item?.variantId === action.payload?.variantId
      )

      if (!current) {
        state.applianceSpreeOrder.lineItems = [
          ...state.applianceSpreeOrder?.lineItems,
          { ...action.payload, quantity: 1 }
        ]

        state.changedLineItems = [
          ...state.changedLineItems,
          { ...action.payload, quantity: 1 }
        ]
      }

      if (current && current?.quantity === -1) {
        state.applianceSpreeOrder.lineItems = [
          ...state.applianceSpreeOrder?.lineItems,
          { ...action.payload, quantity: 1 }
        ]

        state.changedLineItems = state.changedLineItems?.filter(
          (lineItem) => lineItem?.variant?.id !== action.payload?.variantId
        )
      }
    },
    removeLineItem: (
      state: ICheckoutState,
      action: { payload: ISpreeOrderLineItem }
    ) => {
      state.applianceSpreeOrder.lineItems =
        state.applianceSpreeOrder.lineItems?.filter(
          (lineItem) => lineItem?.variant?.id !== action.payload?.variantId
        )

      const current = state.changedLineItems?.find(
        (item) => item?.variantId === action.payload?.variantId
      )

      if (!current) {
        state.changedLineItems = [
          ...state.changedLineItems,
          { ...action.payload, quantity: -1 }
        ]
      } else {
        state.changedLineItems = state.changedLineItems?.filter(
          (lineItem) => lineItem?.variant?.id !== action.payload?.variantId
        )
      }
    }
  }
})

export const {
  setActiveStep,
  setApplianceSpreeOrder,
  setMealSpreeOrder,
  setPaymentMethod,
  addLineItem,
  removeLineItem,
  setIsSubmitButtonDisabled,
  setLowestMonthlyPriceAffirm,
  setIsRedirecting
} = checkoutSlice.actions

export const activeStepSelector = (state: IStoreState) =>
  state.checkout.activeStep
export const applianceSpreeOrderSelector = (state: IStoreState) =>
  state.checkout.applianceSpreeOrder
export const mealSpreeOrderSelector = (state: IStoreState) =>
  state.checkout.mealSpreeOrder
export const paymentMethodSelector = (state: IStoreState) =>
  state.checkout.paymentMethod
export const changedLineItemsSelector = (state: IStoreState) =>
  state.checkout.changedLineItems
export const isSubmitButtonDisabledSelector = (state: IStoreState) =>
  state.checkout.isSubmitButtonDisabled
export const isRedirectingSelector = (state: IStoreState) =>
  state.checkout.isRedirecting
export default checkoutSlice.reducer
