export enum ESpreeOrderState {
  CART = 'cart',
  ADDRESS = 'address',
  DELIVERY = 'delivery',
  PAYMENT = 'payment',
  CONFIRM = 'confirm',
  COMPLETE = 'complete',
  CANCELED = 'canceled',
  AWAITING_RETURN = 'awaiting return',
  RETURN = 'return',
  RESUMED = 'resumed'
}
