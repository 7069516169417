import { API_URL } from '@/configs/env'

export const API_URLS = {
  AUTHENTICATE: `${API_URL}/sessions/authenticate`,
  REFRESH_TOKEN: `${API_URL}/sessions/refresh`,
  RESET_PASSWORD_TOKEN: `${API_URL}/sessions/reset_password_token`,
  RESET_PASSWORD: `${API_URL}/sessions/reset_password`,
  CREATE_LOGIN_TOKEN: `${API_URL}/sessions/login_token/create`,
  MAGIC_AUTHENTICATE: `${API_URL}/sessions/login_token/authenticate`,
  PERSISTANT_AUTHENTICATE: `${API_URL}/sessions/login_token/authenticate`
}
