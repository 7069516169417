import { CLOUDINARY_CLOUD_NAME } from '@/configs/env'

export const getImageId = (src: string): string => src?.match(/([^\\/]+)$/)[0]

export const getBaseImageSrc = (
  width?: number,
  height?: number,
  crop: 'fill' | 'fit' = 'fill'
): string => {
  let url = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/c_${crop}`

  if (width) {
    url += `,w_${width}`
  }

  if (height) {
    url += `,h_${height}`
  }

  return url + ',dpr_auto'
}

export const getCustomSizeImageSrc = (
  src?: string,
  width?: number,
  height?: number,
  crop: 'fill' | 'fit' = 'fill'
): string | null => {
  if (!src) return null

  let baseImageUrl = getBaseImageSrc(width, height, crop)

  baseImageUrl += `/${getImageId(src)}`

  return baseImageUrl
}

export const getBlobExtension = (blobContentType?: string): string | null =>
  blobContentType?.split?.('/')?.[1] ?? null

export const getCustomSizeImageSrcByBlob = (
  blobId?: string,
  blobContentType?: string,
  width?: number,
  height?: number,
  crop: 'fill' | 'fit' = 'fill'
): string | null => {
  if (!blobId) return null

  const extension = getBlobExtension(blobContentType)

  if (!extension) return null

  let baseImageUrl = getBaseImageSrc(width, height, crop)

  baseImageUrl += `/${blobId}.${extension}`

  return baseImageUrl
}

export const getThumbnailImageSrc = (src?: string): string | null =>
  src ? getCustomSizeImageSrc(src, 190, 140) : null

export const getMediumImageSrc = (src?: string): string | null =>
  src ? getCustomSizeImageSrc(src, 650, 400) : null

export const getLargeImageSrc = (src?: string): string | null =>
  src ? getCustomSizeImageSrc(src, 1500, 1244) : null
