import { IAddon, IUnnormalizedAddon } from '@/types'
import {
  getLargeImageSrc,
  getMediumImageSrc,
  getThumbnailImageSrc
} from '@/utils/images'

export const normalizeAddon = (addon: IUnnormalizedAddon): IAddon => ({
  id: Number(addon?.id) ?? null,
  addonId: addon?.addon_id ?? null,
  orderId: addon?.order_id ?? null,
  quantity: addon?.quantity ?? null,
  name: addon?.name ?? null,
  free: addon?.free ?? false,
  images: {
    thumb: getThumbnailImageSrc(addon?.images?.thumb) ?? null,
    medium: getMediumImageSrc(addon?.images?.medium) ?? null,
    large: getLargeImageSrc(addon?.images?.large) ?? null
  },
  familySizeAdjustments: addon?.family_size_adjustments?.map(
    (familySizeAdjustment) => ({
      adjustmentType: familySizeAdjustment?.adjustment_type ?? null,
      familySize: familySizeAdjustment?.family_size ?? null,
      value: familySizeAdjustment?.value ?? null
    })
  ),
  variants: addon?.variants
    ? Object.fromEntries(
        Object.entries(addon?.variants).map(([key, value]) => [
          key,
          {
            itemPriceCents: value?.item_price_cents ?? null,
            premiumPerServingCents: value?.premium_per_serving_cents ?? null,
            pricePerServingCents: value?.price_per_serving_cents ?? null
          }
        ])
      )
    : null,
  sizeDescriptor: addon?.size_descriptor ?? null
})

export const sortAddonsById = (addons: IAddon[]): IAddon[] =>
  addons?.slice()?.sort((a, b) => a?.addonId - b?.addonId)
