import round from 'lodash/round'
import snakeCase from 'lodash/snakeCase'

import { DEFAULT_CURRENCY } from '@/configs/purchase'
import { TButtonTheme } from '@/types'

import { cnMerge } from './cnMerge'

export const getButtonClassnames = (
  theme: TButtonTheme,
  className = ''
): string =>
  cnMerge(
    {
      'btn btn_outline btn_size_default btn_rounded_default':
        theme === 'outline',
      'btn btn_outline-primary btn_size_default btn_rounded_default':
        theme === 'outline-primary',
      'btn btn_primary btn_size_default btn_rounded_default':
        theme === 'primary',
      'btn btn_secondary btn_size_default btn_rounded_default':
        theme === 'secondary',
      'btn btn_danger btn_size_default btn_rounded_default': theme === 'danger',
      'btn btn_outline btn_size_small btn_rounded_large':
        theme === 'small-outline',
      'btn btn_primary btn_size_small btn_rounded_large':
        theme === 'small-primary',
      'btn btn_secondary btn_size_small btn_rounded_large':
        theme === 'small-secondary',
      'btn btn_danger btn_size_small btn_rounded_large':
        theme === 'small-danger',
      'btn btn_invert-primary btn_size_small btn_rounded_large':
        theme === 'small-invert-primary',
      'btn btn_invert-danger btn_size_small btn_rounded_large':
        theme === 'small-invert-danger'
    },
    className
  )

export const convertSecondsToMinutes = (seconds: number): number => {
  return Math.floor(seconds / 60)
}

export const convertCentsToPrice = ({
  cents,
  precision,
  minimumFractionDigits,
  isAbsolute,
  positivePrefix,
  positivePostfix,
  negativePrefix,
  negativePostfix,
  zeroPlaceholder
}: {
  cents: number
  precision?: number
  minimumFractionDigits?: number
  isAbsolute?: boolean
  positivePrefix?: string
  positivePostfix?: string
  negativePrefix?: string
  negativePostfix?: string
  zeroPlaceholder?: string
}): string => {
  if (cents === 0 && (zeroPlaceholder || zeroPlaceholder === ''))
    return zeroPlaceholder

  let float: number = round(cents / 100, precision)

  if (isAbsolute) float = Math.abs(float)

  const result = float.toLocaleString('en-US', {
    style: 'currency',
    currency: DEFAULT_CURRENCY,
    minimumFractionDigits
  })

  if (cents > 0) {
    return `${positivePrefix ?? ''}${result}${positivePostfix ?? ''}`
  }

  return `${negativePrefix ?? ''}${result}${negativePostfix ?? ''}`
}

export const scrollElementIntoView = (selector: string): void =>
  document?.querySelector(selector)?.scrollIntoView({
    block: 'center',
    behavior: 'smooth'
  })

export const convertStringPriceToCents = (price?: string): number =>
  price ? Math.round(parseFloat(price) * 100) : 0

export const getIsVideoPlaying = (video: HTMLVideoElement): boolean =>
  video.currentTime > 0 &&
  !video.paused &&
  !video.ended &&
  video.readyState > video.HAVE_CURRENT_DATA

export const covertObjectKeysToSnakeCase = (
  object: Record<string, unknown>
): Record<string, unknown> =>
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => [snakeCase(key), value])
  )

export const getSuggestedAddress = (place: google.maps.places.PlaceResult) => {
  let address1 = ''
  let city = ''
  let zipcode = ''
  let stateAbbr = ''

  place.address_components?.forEach(
    (addressComponent: google.maps.GeocoderAddressComponent) => {
      const componentType = addressComponent.types[0]

      switch (componentType) {
        case 'street_number':
          address1 = `${addressComponent.long_name} ${address1}`
          break
        case 'route':
          address1 += addressComponent.short_name
          break
        case 'postal_code':
          zipcode = `${addressComponent.long_name}${zipcode}`
          break
        case 'locality':
          city = addressComponent.long_name
          break
        case 'administrative_area_level_1': {
          stateAbbr = addressComponent.short_name
          break
        }
      }
    }
  )

  return { address1, city, stateAbbr, zipcode }
}
