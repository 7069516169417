import { EXCLUDED_WEEK_START_DELIVERIES_DATES } from '@/configs/deliveries'
import { IDelivery, IUnnormalizedDelivery } from '@/types'

export const normalizeDelivery = (
  delivery: IUnnormalizedDelivery
): IDelivery => ({
  id: delivery?.id,
  state: delivery?.state,
  weekStartDate: delivery?.week_start_date,
  deliveryDate: delivery?.delivery_date,
  holiday: delivery?.holiday
})

export const filterOutExcludedDeliveries = (
  deliveries: IDelivery[]
): IDelivery[] =>
  deliveries?.filter(
    (delivery) =>
      !EXCLUDED_WEEK_START_DELIVERIES_DATES?.includes(delivery?.weekStartDate)
  )
