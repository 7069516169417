export const EXTERNAL_LINKS = {
  HELP_DESK: 'https://support.suvie.com/hc/en-us/',
  BROCHURE: 'https://www.suvie.com/',
  SHOP_PAGE: 'https://www.suvie.com/shop',
  RECIPES: 'https://www.suvie.com/recipes/',
  FAQ: 'https://www.suvie.com/faq/',
  TERMS_AND_CONDITIONS: 'https://www.suvie.com/terms-and-conditions/',
  PRIVACY_POLICY: 'https://www.suvie.com/privacy-policy/',
  FREE_TRIAL: 'https://www.suvie.com/free-trial',
  BLOG: 'https://blog.suvie.com/',
  ACCESSIBILITY: 'https://www.suvie.com/accessibility',
  FACEBOOK_GROUP: 'https://www.facebook.com/SuvieFood/'
}
