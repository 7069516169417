import axios, { AxiosResponse } from 'axios'

import { API_URLS } from '@/configs/api'
import { MAGIC_SIGN_IN_ERROR_MESSAGE } from '@/configs/messages'
import { IAuthenticateResponse, ICredentials, IJwt, IToken } from '@/types'

export const getAuthenticateUrl = (credentials: ICredentials): string => {
  if ('accessToken' in credentials) {
    return API_URLS.MAGIC_AUTHENTICATE
  }

  if ('loginToken' in credentials) {
    return API_URLS.PERSISTANT_AUTHENTICATE
  }

  return API_URLS.AUTHENTICATE
}

export const getAuthenticateData = (
  credentials: ICredentials
): {
  action?: {
    name: string
  }
  input: {
    email?: string
    password?: string
    // eslint-disable-next-line camelcase
    login_token?: string
  }
} => {
  if ('accessToken' in credentials) {
    return { input: { login_token: credentials.accessToken } }
  }

  if ('loginToken' in credentials) {
    return { input: { login_token: credentials.loginToken } }
  }

  return {
    action: {
      name: 'loginUser'
    },
    input: {
      email: credentials.email,
      password: credentials.password
    }
  }
}

export const authenticate = async (
  credentials: ICredentials
): Promise<IToken> => {
  try {
    if ('existedToken' in credentials) {
      const token = JSON.parse(decodeURIComponent(credentials.existedToken))

      return {
        accessToken: token?.Jwtkey ?? null,
        tokenExpiresIn: token?.tokenExpiresIn ?? null,
        userId: token?.userId ?? null
      }
    }

    const response = await axios.post<IAuthenticateResponse>(
      getAuthenticateUrl(credentials),
      getAuthenticateData(credentials),
      { withCredentials: true }
    )

    return {
      email: credentials?.email,
      accessToken: response.data.Jwtkey ?? null,
      tokenExpiresIn: response.data.tokenExpiresIn ?? null,
      userId: response.data.userId ?? null,
      targetUrl: response.data?.targetUrl ?? null
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message ?? MAGIC_SIGN_IN_ERROR_MESSAGE
    )
  }
}

export const resetPasswordToken = async (
  email: string
): Promise<AxiosResponse<Record<string, unknown>>> =>
  axios.post(API_URLS.RESET_PASSWORD_TOKEN, {
    input: { email }
  })

export const resetPassword = async (
  token: string,
  password: string,
  passwordConfirmation: string
): Promise<AxiosResponse<Record<string, unknown>>> =>
  axios.post(API_URLS.RESET_PASSWORD, {
    input: {
      token,
      password,
      password_confirmation: passwordConfirmation
    }
  })

export const generateMagicLoginToken = async (
  email: string
): Promise<AxiosResponse<Record<string, unknown>>> =>
  axios.post(API_URLS.CREATE_LOGIN_TOKEN, {
    input: { email }
  })

export const parseJwtOnServer = <T>(token: string): T | null => {
  try {
    return JSON.parse(
      Buffer?.from(token?.split('.')?.[1], 'base64')?.toString()
    )
  } catch (error) {
    return null
  }
}

export const parseJwtInBrowser = <T>(token: string): T | null => {
  try {
    const base64Url = token?.split('.')?.[1]
    const base64 = base64Url?.replace?.(/-/g, '+')?.replace?.(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        ?.atob(base64)
        ?.split('')
        ?.map(
          (string) =>
            '%' + ('00' + string?.charCodeAt(0)?.toString(16))?.slice(-2)
        )
        ?.join('')
    )

    return JSON.parse(jsonPayload)
  } catch (error) {
    return null
  }
}

export const parseJwt = <T>(token: string): T | null =>
  typeof window === 'undefined'
    ? parseJwtOnServer(token)
    : parseJwtInBrowser(token)

export const getIsJwtExpired = (jwt: IJwt): boolean =>
  !jwt?.exp || Date.now() > jwt?.exp * 1000
