import { createSlice } from '@reduxjs/toolkit'

import {
  EPaymentMethodName,
  ETradeUpCheckoutStep,
  ISpreeOrder,
  ISpreeOrderLineItem,
  IStoreState,
  TPaymentMethodName,
  TTradeUpCheckoutStep
} from '@/types'

export interface ITradeUpCheckoutState {
  activeStep: TTradeUpCheckoutStep
  spreeOrder: ISpreeOrder | null
  paymentMethod: TPaymentMethodName
  changedLineItems: ISpreeOrderLineItem[]
  isSubmitButtonDisabled: boolean
  isRedirecting: boolean
  isTotalsLoading: boolean
}

const initialState: ITradeUpCheckoutState = {
  activeStep: ETradeUpCheckoutStep.ADDONS,
  spreeOrder: null,
  paymentMethod: EPaymentMethodName.CREDIT_CARD,
  changedLineItems: [],
  isSubmitButtonDisabled: true,
  isRedirecting: false,
  isTotalsLoading: true
}

export const tradeUpCheckoutSlice = createSlice({
  name: 'tradeUpCheckout',
  initialState,
  reducers: {
    setActiveStep: (
      state: ITradeUpCheckoutState,
      action: { payload: TTradeUpCheckoutStep }
    ) => {
      state.activeStep = action.payload
    },
    setSpreeOrder: (
      state: ITradeUpCheckoutState,
      action: { payload: ISpreeOrder }
    ) => {
      state.spreeOrder = action.payload
      state.changedLineItems = []
    },
    setPaymentMethod: (
      state: ITradeUpCheckoutState,
      action: { payload: TPaymentMethodName }
    ) => {
      state.paymentMethod = action.payload
    },
    setIsSubmitButtonDisabled: (
      state: ITradeUpCheckoutState,
      action: { payload: boolean }
    ) => {
      state.isSubmitButtonDisabled = action.payload
    },
    setIsRedirecting: (
      state: ITradeUpCheckoutState,
      action: { payload: boolean }
    ) => {
      state.isRedirecting = action.payload
    },
    setIsTotalsLoading: (
      state: ITradeUpCheckoutState,
      action: { payload: boolean }
    ) => {
      state.isTotalsLoading = action.payload
    },
    addLineItem: (
      state: ITradeUpCheckoutState,
      action: { payload: ISpreeOrderLineItem }
    ) => {
      const current = state.changedLineItems?.find(
        (item) => item?.variantId === action.payload?.variantId
      )

      if (!current) {
        state.spreeOrder = {
          ...(state.spreeOrder ?? {}),
          lineItems: [
            ...(state.spreeOrder?.lineItems ?? []),
            { ...action.payload, quantity: 1 }
          ]
        }

        state.changedLineItems = [
          ...state.changedLineItems,
          { ...action.payload, quantity: 1 }
        ]
      }

      if (current && current?.quantity === -1) {
        state.spreeOrder = {
          ...(state.spreeOrder ?? {}),
          lineItems: [
            ...(state.spreeOrder?.lineItems ?? []),
            { ...action.payload, quantity: 1 }
          ]
        }

        state.changedLineItems = state.changedLineItems?.filter(
          (lineItem) => lineItem?.variant?.id !== action.payload?.variantId
        )
      }
    },
    removeLineItem: (
      state: ITradeUpCheckoutState,
      action: { payload: ISpreeOrderLineItem }
    ) => {
      state.spreeOrder.lineItems = state.spreeOrder.lineItems?.filter(
        (lineItem) => lineItem?.variant?.id !== action.payload?.variantId
      )

      const current = state.changedLineItems?.find(
        (item) => item?.variantId === action.payload?.variantId
      )

      if (!current) {
        state.changedLineItems = [
          ...state.changedLineItems,
          { ...action.payload, quantity: -1 }
        ]
      } else {
        state.changedLineItems = state.changedLineItems?.filter(
          (lineItem) => lineItem?.variant?.id !== action.payload?.variantId
        )
      }
    }
  }
})

export const {
  setActiveStep,
  setSpreeOrder,
  setPaymentMethod,
  addLineItem,
  removeLineItem,
  setIsSubmitButtonDisabled,
  setIsRedirecting,
  setIsTotalsLoading
} = tradeUpCheckoutSlice.actions

export const activeStepSelector = (state: IStoreState) =>
  state.tradeUpCheckout.activeStep
export const spreeOrderSelector = (state: IStoreState) =>
  state.tradeUpCheckout.spreeOrder
export const paymentMethodSelector = (state: IStoreState) =>
  state.tradeUpCheckout.paymentMethod
export const changedLineItemsSelector = (state: IStoreState) =>
  state.tradeUpCheckout.changedLineItems
export const isSubmitButtonDisabledSelector = (state: IStoreState) =>
  state.tradeUpCheckout.isSubmitButtonDisabled
export const isTotalsLoadingSelector = (state: IStoreState) =>
  state.tradeUpCheckout.isTotalsLoading
export const isRedirectingSelector = (state: IStoreState) =>
  state.tradeUpCheckout.isRedirecting

export default tradeUpCheckoutSlice.reducer
