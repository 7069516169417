export const IS_DEV = process.env.APP_ENV === 'development'
export const IS_PROD = process.env.APP_ENV === 'production'
export const IS_PREVIEW = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'

export const MAIN_DOMAIN = 'suvie.com'
export const PLANNER_SUB_DOMAIN = 'app'
export const STAGING_SUB_DOMAIN = 'staging'

export const VERCEL_ENV = process.env.VERCEL_ENV
export const NEXT_PUBLIC_VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL

export const API_URL = process.env.API_URL
export const GRAPHQL_URL = process.env.GRAPHQL_URL
export const STRAPI_GRAPHQL_URL = process.env.STRAPI_GRAPHQL_URL
export const CLOUDINARY_CLOUD_NAME = process.env.CLOUDINARY_CLOUD_NAME
export const STRIPE_PUBLIC_KEY = process.env.STRIPE_PUBLIC_KEY
export const TEST_STRIPE_PUBLIC_KEY = process.env.TEST_STRIPE_PUBLIC_KEY
export const TEST_MODE_SECRET = process.env.TEST_MODE_SECRET
export const GTM_ID = process.env.GTM_ID
export const AFFIRM_PUBLIC_KEY = process.env.AFFIRM_PUBLIC_KEY
export const AFFIRM_PUBLIC_URL = process.env.AFFIRM_PUBLIC_URL
export const GOOGLE_PLACE_API_KEY = process.env.GOOGLE_PLACE_API_KEY
export const CLYDE_API_KEY = process.env.CLYDE_API_KEY

export const FIRST_TIME_ORDER_PROMOTION_ID = parseInt(
  process.env.FIRST_TIME_ORDER_PROMOTION_ID
)
export const THANKS_FOR_STICKING_WITH_US_PROMOTION_ID = parseInt(
  process.env.THANKS_FOR_STICKING_WITH_US_PROMOTION_ID
)
