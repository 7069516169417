import {
  ApolloClient,
  ApolloError,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'

import { ACCESS_TOKEN } from '@/configs/cookies'
import { GRAPHQL_URL, STRAPI_GRAPHQL_URL } from '@/configs/env'
import { DEFAULT_ERROR_MESSAGE } from '@/configs/messages'
import { IJwt, ISession } from '@/types'
import { getIsJwtExpired, parseJwt } from '@/utils/auth'
import { maybeGetCookieValueByName } from '@/utils/cookies'

let apolloBrowserClient: ApolloClient<unknown>

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

export const createHeaders = ({
  accessToken,
  userId,
  userRole
}: {
  accessToken?: string
  userId?: number
  userRole?: string
}): Record<string, string> => {
  const headers: Record<string, string> = {}

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
    headers['X-Hasura-User-Id'] = userId?.toString()
    headers['X-Hasura-Role'] = userRole ?? 'user'
  } else {
    headers['X-Hasura-Role'] = 'anonymous'
  }

  return headers
}

const createLink = (headers: Record<string, string>) =>
  new RetryLink().split(
    (operation) => operation.getContext().isStrapi,
    new HttpLink({ uri: STRAPI_GRAPHQL_URL, credentials: 'same-origin' }),
    new HttpLink({ uri: GRAPHQL_URL, credentials: 'include', headers })
  )

export const createServerSideApolloClient = ({
  session,
  initialApolloState
}: {
  session: ISession
  initialApolloState?: NormalizedCacheObject
}): ApolloClient<Record<string, unknown>> => {
  const headers = createHeaders({
    accessToken: session?.accessToken,
    userId: session?.userId,
    userRole: session?.userRole
  })

  const link = createLink(headers)

  return new ApolloClient({
    ssrMode: true,
    link: from([errorLink, link]),
    cache: new InMemoryCache().restore(initialApolloState ?? {})
  })
}

export const createBrowserSideApolloClient = ({
  session,
  initialApolloState
}: {
  session: ISession
  initialApolloState?: NormalizedCacheObject
}): ApolloClient<Record<string, unknown>> => {
  const headersContext = setContext(({ context }, { headers }) => {
    const accessToken = maybeGetCookieValueByName(ACCESS_TOKEN)
    const jwt = accessToken ? parseJwt<IJwt>(accessToken) : null
    const isJwtExpired = getIsJwtExpired(jwt)

    const defaultRole =
      jwt?.['https://hasura.io/jwt/claims']?.['x-hasura-default-role']

    return {
      headers: {
        ...headers,
        ...(context?.headers ?? {}),
        ...(!isJwtExpired &&
        (jwt?.role === 'user' ||
          defaultRole === 'user' ||
          defaultRole === 'admin')
          ? {
              Authorization: `Bearer ${accessToken}`,
              'X-Hasura-User-Id':
                jwt?.sub ??
                jwt?.['https://hasura.io/jwt/claims']?.['x-hasura-user-id'],
              'X-Hasura-Role': 'user'
            }
          : {})
      }
    }
  })

  const headers = createHeaders({
    accessToken: session?.accessToken,
    userId: session?.userId,
    userRole: session?.userRole
  })

  const link = createLink(headers)

  return new ApolloClient({
    ssrMode: false,
    link: headersContext.concat(from([errorLink, link])),
    cache: new InMemoryCache().restore(initialApolloState ?? {})
  })
}

export const initializeApolloClient = ({
  session,
  initialApolloState
}: {
  session: ISession
  initialApolloState?: NormalizedCacheObject
}) => {
  if (typeof window === 'undefined')
    return createServerSideApolloClient({ session, initialApolloState })

  if (!apolloBrowserClient) {
    apolloBrowserClient = createBrowserSideApolloClient({
      session,
      initialApolloState
    })
  }

  return apolloBrowserClient
}

export const getErrorMessage = (error: ApolloError): string =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  error?.graphQLErrors[0]?.extensions?.error?.errors?.base?.[0] ??
  error?.graphQLErrors[0]?.message ??
  DEFAULT_ERROR_MESSAGE

export const getErrorCode = (error: ApolloError): number | string =>
  error?.graphQLErrors[0]?.extensions?.code as number | string
