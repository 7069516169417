import flatten from 'lodash/flatten'

import {
  EMealCategoryKey,
  IFilterItem,
  IMeal,
  IMealCategory,
  IOrder,
  IUnnormalizedMealCategory
} from '@/types'
import { filterByAllergensFilter, normalizeMeal } from '@/utils/meals'

export const normalizeMealCategory = (
  mealCategory: IUnnormalizedMealCategory
): IMealCategory => ({
  id: mealCategory?.id ?? null,
  key: mealCategory?.key ?? null,
  name: mealCategory?.name ?? null,
  description: mealCategory?.description ?? null,
  shortDescription: mealCategory?.shortDescription ?? null,
  addon: mealCategory?.addon ?? false,
  defaultPrice: mealCategory?.defaultPrice ?? false,
  applianceTypes:
    mealCategory?.applianceTypes?.map((applianceType) => ({
      id: applianceType?.applianceType?.id ?? null,
      key: applianceType?.applianceType?.key ?? null,
      name: applianceType?.applianceType?.name ?? null
    })) ?? [],
  meals: mealCategory?.meals?.map((meal) => normalizeMeal(meal.meal)) ?? []
})

export const normalizeMealCategories = (
  mealCategories: IUnnormalizedMealCategory[]
): IMealCategory[] =>
  mealCategories?.map((mealCategory) => normalizeMealCategory(mealCategory))

export const getAllMeals = (mealCategories: IMealCategory[]): IMeal[] =>
  flatten(mealCategories?.map((mealCategory) => mealCategory?.meals))

export const getMealsInCart = (
  mealCategories: IMealCategory[],
  order: IOrder
): IMeal[] =>
  getAllMeals(mealCategories)?.filter(
    (meal) =>
      order?.mealOrders?.some((item) => item?.mealId === meal?.id) ||
      order?.addons?.some((item) => item?.addonId === meal?.id)
  )

export const getProteinPlusSaucesFilteredByAllergensFilter = (
  mealCategories: IMealCategory[],
  allergensMealsFilter: IFilterItem[]
): IMeal[] => {
  const meals = getAllMeals(mealCategories)
  const proteinPlusSauces = meals.filter((meal) => meal?.proteinPlusSauce)
  return filterByAllergensFilter(proteinPlusSauces, allergensMealsFilter)
}

export const getIsCategoryFromSuvieMealsCategory = (
  mealCategory: IMealCategory
): boolean =>
  mealCategory.key === 'sous-vide' ||
  mealCategory.key === 'sous-vide-meals' ||
  mealCategory.key === 'sous-vide-gen-2' ||
  mealCategory.key === 'rapid-cook-gen-2' ||
  mealCategory.key === 'slow-cook-gen-1' ||
  mealCategory.key === 'slow-cook'

export const createSuvieMealsCategory = (
  mealCategories: IMealCategory[]
): IMealCategory[] => {
  const categoriesFromSuvieMealsCategory: IMealCategory[] = []

  const otherCategories: IMealCategory[] = []

  mealCategories?.forEach((mealCategory) => {
    if (getIsCategoryFromSuvieMealsCategory(mealCategory)) {
      categoriesFromSuvieMealsCategory.push(mealCategory)
    } else {
      otherCategories.push(mealCategory)
    }
  })

  const suvieMealsCategory: IMealCategory = {
    key: EMealCategoryKey.SUVIE_MEALS,
    name: 'Suvie Meals',
    shortDescription:
      'Gourmet meals delivered right to your home. The more servings you add the more savings you receive.',
    meals: getAllMeals(categoriesFromSuvieMealsCategory)
  }

  return [suvieMealsCategory, ...otherCategories]
}
