import { createSlice } from '@reduxjs/toolkit'

export interface IUiState {
  isMenuVisible: boolean
  isShoppingCartVisible: boolean
}

const initialState: IUiState = {
  isMenuVisible: false,
  isShoppingCartVisible: false
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openMenu: (state) => {
      state.isMenuVisible = true
    },
    closeMenu: (state) => {
      state.isMenuVisible = false
    },
    toggleMenu: (state) => {
      state.isMenuVisible = !state.isMenuVisible
    },
    openShoppingCart: (state) => {
      state.isShoppingCartVisible = true
    },
    closeShoppingCart: (state) => {
      state.isShoppingCartVisible = false
    },
    toggleShoppingCart: (state) => {
      state.isShoppingCartVisible = !state.isShoppingCartVisible
    }
  }
})

export const {
  openMenu,
  closeMenu,
  toggleMenu,
  openShoppingCart,
  closeShoppingCart,
  toggleShoppingCart
} = uiSlice.actions

export const isMenuVisibleSelector = (state) => state.ui.isMenuVisible
export const isShoppingCartVisibleSelector = (state) =>
  state.ui.isShoppingCartVisible

export default uiSlice.reducer
