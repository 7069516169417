import {
  EOrderStatus,
  ESpreeOrderPaymentState,
  ESpreeOrderShipmentState,
  ESpreeOrderState,
  IOrder,
  IUnnormalizedOrder,
  TOrderPriceState
} from '@/types'
import { normalizeAddon } from '@/utils/addons'
import { normalizeDelivery } from '@/utils/deliveries'
import { normalizeMealOrder } from '@/utils/mealOrders'
import { getCreditQuantity, getServingsPerMeal } from '@/utils/meals'
import { normalizeSpreeOrder } from '@/utils/spreeOrders'

export const normalizeOrder = (order: IUnnormalizedOrder): IOrder => ({
  id: order?.attributes?.id ?? null,
  addons:
    order?.attributes?.addons?.map((addon) => normalizeAddon(addon)) ?? [],
  delivery: order?.attributes?.delivery
    ? normalizeDelivery(order?.attributes?.delivery)
    : null,
  deliveryDate: order?.attributes?.delivery_date ?? null,
  locked: order?.attributes?.locked ?? false,
  mealOrders:
    order?.attributes?.meal_orders?.map((mealOrder) =>
      normalizeMealOrder(mealOrder)
    ) ?? [],
  skip: order?.attributes?.skip ?? true,
  status: order?.attributes?.status ?? null,
  trackingLink: order?.attributes?.tracking_link ?? null,
  trackingNumber: order?.attributes?.tracking_number ?? null,
  trackingStatus: order?.attributes?.tracking_status ?? null,
  defaultFrequency: order?.attributes?.default_frequency ?? true,
  spreeOrder: order?.attributes?.spree_order
    ? normalizeSpreeOrder(order?.attributes?.spree_order)
    : null,
  applianceSpreeOrder: order?.attributes?.appliance_spree_order
    ? normalizeSpreeOrder(order?.attributes?.appliance_spree_order)
    : null,
  isTouched: false
})

export const normalizeOrders = (orders: IUnnormalizedOrder[]): IOrder[] =>
  orders.map((order) => normalizeOrder(order))

export const getIsOrderLocked = (order: IOrder): boolean =>
  order?.locked || order?.status === EOrderStatus.LOCKED

export const getIsOrderDraft = (order: IOrder): boolean =>
  order?.status === EOrderStatus.DRAFT

export const getIsOrderActive = (order: IOrder): boolean =>
  !getIsOrderLocked(order) && order?.status === EOrderStatus.ACTIVE

export const getIsOrderPaid = (order: IOrder): boolean =>
  order?.spreeOrder?.paymentState === ESpreeOrderPaymentState.PAID ||
  order?.spreeOrder?.paymentState === ESpreeOrderPaymentState.COMPLETED

export const getIsOrderShipped = (order: IOrder): boolean =>
  order?.spreeOrder?.shipmentState === ESpreeOrderShipmentState.SHIPPED

export const getIsOrderDeclined = (order: IOrder): boolean =>
  order?.status === EOrderStatus.DECLINED ||
  order?.spreeOrder?.paymentState === ESpreeOrderPaymentState?.FAILED

export const getIsOrderCancelled = (order: IOrder): boolean =>
  order?.status === EOrderStatus.CANCELLED ||
  order?.spreeOrder?.state === ESpreeOrderState.CANCELED

export const getActiveOrders = (orders: IOrder[]): IOrder[] =>
  orders?.filter((order) => getIsOrderActive(order))

export const getLabelForStatus = (order: IOrder) => {
  if (getIsOrderDeclined(order)) {
    return 'Declined'
  }

  if (getIsOrderCancelled(order)) {
    return 'Cancelled'
  }

  if (getIsOrderShipped(order)) {
    return 'Shipped'
  }

  if (getIsOrderPaid(order)) {
    return 'Paid'
  }

  if (getIsOrderDraft(order)) {
    return 'Draft'
  }

  if (getIsOrderActive(order)) {
    return 'Active'
  }

  if (getIsOrderLocked(order)) {
    return 'Locked'
  }
}

export const getSelectedMealsAmount = (order: IOrder, id: number): number => {
  const mealOrder = order?.mealOrders.find(
    (mealOrder) => mealOrder?.mealId === id
  )

  return mealOrder?.quantity ?? 0
}

export const getSelectedAddonsAmount = (order: IOrder, id: number): number => {
  const addon = order?.addons.find((addon) => addon.addonId === id)

  return addon?.quantity ?? 0
}

export const getOrderPriceState = (
  currentMealsServingsAmount: number,
  secondServingsAmount: number,
  maxMealsServingsAmount: number
): TOrderPriceState => {
  if (currentMealsServingsAmount >= maxMealsServingsAmount) {
    return 'lowest'
  } else if (currentMealsServingsAmount >= secondServingsAmount) {
    return 'discounted'
  } else {
    return 'original'
  }
}

export const getCurrentOrderIndex = (
  currentOrderId: number,
  orders: IOrder[]
): number => orders?.findIndex((order) => order?.id === currentOrderId)

export const getOrderRelativeToCurrent = (
  currentOrderId: number,
  orders: IOrder[],
  relativeIndex: number
): IOrder | null => {
  const currentOrderIndex = getCurrentOrderIndex(currentOrderId, orders)

  return currentOrderIndex !== -1
    ? orders?.[currentOrderIndex + relativeIndex] ?? null
    : null
}

export const getOrdersForNavigation = (
  currentOrderId: number,
  orders: IOrder[],
  quantity: number
): IOrder[] => {
  const currentOrderIndex = getCurrentOrderIndex(currentOrderId, orders)

  if (currentOrderIndex === -1) return []

  if (currentOrderIndex >= orders.length - quantity) {
    return orders?.slice(-quantity)
  }

  return orders?.slice(currentOrderIndex, currentOrderIndex + quantity)
}

export const getMealsAmount = (
  order: IOrder,
  accountFamilySize: number
): number =>
  order?.mealOrders.reduce((result, current) => {
    const creditQuantity = getCreditQuantity(current?.meal, accountFamilySize)

    return result + creditQuantity * current?.quantity
  }, 0)

export const getAddonsAmount = (
  order: IOrder,
  accountFamilySize: number
): number =>
  order?.addons.reduce((result, current) => {
    const creditQuantity = getCreditQuantity(current, accountFamilySize)

    return result + creditQuantity * current?.quantity
  }, 0)

export const getItemsAmount = (
  order: IOrder,
  accountFamilySize: number
): number => {
  const mealsAmount = getMealsAmount(order, accountFamilySize)
  const addonsAmount = getAddonsAmount(order, accountFamilySize)

  return mealsAmount + addonsAmount
}

export const getMealsServingsAmount = (
  order: IOrder,
  accountFamilySize: number
): number =>
  order?.mealOrders.reduce((result, current) => {
    const servingPerMeal = getServingsPerMeal(current?.meal, accountFamilySize)

    return result + current?.quantity * servingPerMeal
  }, 0)

export const getEditOrderValues = ({
  order,
  skip = false,
  startDate
}: {
  order: IOrder
  skip?: boolean
  startDate?: string
}) => ({
  id: order?.id,
  order: {
    addons: order?.addons.map((addon) => ({
      id: addon?.addonId,
      quantity: addon?.quantity
    })),
    meal_orders: order?.mealOrders.map((mealOrder) => ({
      id: mealOrder?.mealId,
      quantity: mealOrder?.quantity,
      sauce_substitution_sku: mealOrder?.sauceSubstitutionSku
    })),
    skip,
    startDate
  }
})

export const getNextNotHolidayOrder = (
  currentOrder: IOrder,
  orders: IOrder[]
): IOrder =>
  orders?.find(
    (item) => item?.id > currentOrder?.id && !item?.delivery?.holiday
  )

export const getMealsAndAddonsIds = (order: IOrder): number[] => [
  ...(order?.mealOrders.map((mealOrder) => mealOrder?.mealId) ?? []),
  ...(order?.addons.map((addon) => addon?.addonId) ?? [])
]
