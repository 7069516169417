import { createSlice } from '@reduxjs/toolkit'
import get from 'lodash/get'
import reject from 'lodash/reject'

import {
  IAddon,
  IMeal,
  IOrder,
  ISpreeProductVariant,
  IStoreState,
  IUnnormalizedOrder
} from '@/types'
import {
  getSelectedAddonsAmount,
  getSelectedMealsAmount,
  normalizeOrder
} from '@/utils/orders'

export interface IOrderState {
  value?: IOrder
  isLoading?: boolean
}

const initialState: IOrderState = {
  value: null,
  isLoading: false
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    set: (state, action: { payload: IUnnormalizedOrder }) => {
      state.value = action.payload ? normalizeOrder(action.payload) : null
    },
    setNormalized: (state, action: { payload: IOrder }) => {
      state.value = action.payload
    },
    setIsLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload
    },
    addLineItem: (
      state,
      action: {
        payload: ISpreeProductVariant
      }
    ) => {
      const { id } = action.payload

      state.value.isTouched = true

      state.value.spreeOrder.lineItems = [
        ...state.value.spreeOrder.lineItems,
        { variantId: id, quantity: 1 }
      ]
    },
    addMeal: (
      state,
      action: {
        payload: { meal: IMeal }
      }
    ) => {
      const order = state.value
      const { meal } = action.payload

      const selectedMealsQuantity = getSelectedMealsAmount(order, meal?.id)

      state.value.isTouched = true

      state.value.mealOrders =
        selectedMealsQuantity === 0
          ? [
              ...order.mealOrders,
              {
                mealId: meal?.id,
                orderId: order.id,
                quantity: 1,
                meal
              }
            ]
          : (order.mealOrders = order.mealOrders.map((item) =>
              item.mealId === meal?.id
                ? { ...item, quantity: item.quantity + 1 }
                : item
            ))
    },
    removeMeal: (state, action: { payload: { id: number } }) => {
      const order = state.value
      const { id } = action.payload

      const selectedMealsQuantity = getSelectedMealsAmount(order, id)

      state.value.isTouched = true

      state.value.mealOrders =
        selectedMealsQuantity === 1
          ? reject(order.mealOrders, { mealId: id })
          : order.mealOrders.map((item) =>
              item.mealId === id
                ? { ...item, quantity: item.quantity - 1 }
                : item
            )
    },
    removeMeals: (state, action: { payload: { ids: number[] } }) => {
      const order = state.value
      const { ids } = action.payload

      state.value.isTouched = true

      state.value.mealOrders = order?.mealOrders?.filter(
        (mealOrder) => !ids?.includes(mealOrder?.mealId)
      )

      state.value.addons = order?.addons?.filter(
        (addon) => !ids?.includes(addon?.addonId)
      )
    },
    addAddon: (state, action: { payload: { addon: IMeal | IAddon } }) => {
      const order = state.value
      const { addon } = action.payload
      const id = get(addon, 'addonId') ?? addon.id

      const selectedAddonsQuantity = getSelectedAddonsAmount(order, id)

      state.value.isTouched = true

      state.value.addons =
        selectedAddonsQuantity === 0
          ? [
              ...order.addons,
              {
                orderId: order.id,
                addonId: id,
                quantity: 1,
                ...addon
              }
            ]
          : (order.addons = order.addons.map((item) =>
              item.addonId === id
                ? { ...item, quantity: item.quantity + 1 }
                : item
            ))
    },
    removeAddon: (state, action: { payload: { id: number } }) => {
      const order = state.value
      const { id } = action.payload

      const selectedAddonsQuantity = getSelectedAddonsAmount(order, id)

      state.value.isTouched = true

      state.value.addons =
        selectedAddonsQuantity === 1
          ? reject(order.addons, { addonId: id })
          : order.addons.map((item) =>
              item.addonId === id
                ? { ...item, quantity: item.quantity - 1 }
                : item
            )
    },
    toggleSauce: (
      state,
      action: { payload: { id: number; sauceSku: string } }
    ) => {
      const order = state.value
      const { id, sauceSku } = action.payload

      state.value.isTouched = true

      state.value.mealOrders = order.mealOrders.map((item) =>
        item.mealId === id ? { ...item, sauceSubstitutionSku: sauceSku } : item
      )
    }
  }
})

export const {
  set,
  setNormalized,
  setIsLoading,
  addLineItem,
  addMeal,
  removeMeal,
  removeMeals,
  addAddon,
  removeAddon,
  toggleSauce
} = orderSlice.actions

export const orderSelector = (state: IStoreState) => state.order.value
export const isOrderLoadingSelector = (state: IStoreState) =>
  state.order.isLoading

export default orderSlice.reducer
