import { ReactNode, useMemo } from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import { createStore } from '@/store/store'
import { IAccount } from '@/types'

type AppReduxProviderProps = {
  children: ReactNode
  account?: IAccount
}

const AppReduxProvider = ({ children, account }: AppReduxProviderProps) => {
  const store = useMemo(
    () => createStore({ account: { value: account } }),
    [account]
  )

  return <ReduxProvider store={store}>{children}</ReduxProvider>
}

export default AppReduxProvider
