export const DEFAULT_COUNTRY_VALUE = 'USA'
export const DEFAULT_COUNTRY_ISO = 'US'

export const DEFAULT_CURRENCY = 'USD'

export const DEFAULT_PRODUCT_SKU = 'PKG_CHEF_SV3_A1'
export const DEFAULT_TRADE_UP_PRODUCT_SKU = 'PKG_SV3_T'
export const DEFAULT_MULTISTEP_CHECKOUT_PRODUCT_SKU = 'PKG_SV3_A1'

export const PRODUCT_TOOLTIPS = {
  SUVIE201: {
    '4mealboxpurchase':
      'To be eligible for the $249 Suvie offer, you must order 4 Smart Meal boxes within 6 months of receiving your Suvie. If you return your Suvie in the 100 Day Trial period you’ll get your money back and owe nothing else. If you keep your Suvie and don’t order any boxes, you will be charged $80 for each box that was not ordered up to a maximum of $320.'
  },
  SUVIE201B: {
    '2mealboxpurchase':
      'To be eligible for the $399 Suvie offer, you must order 2 Smart Meal boxes within 6 months of receiving your Suvie. If you return your Suvie in the 100 Day Trial period you’ll get your money back and owe nothing else. If you keep your Suvie and don’t order any boxes, you will be charged $80 for each box that was not ordered up to a maximum of $160.'
  },
  SUVIE201C: {
    '4mealboxpurchase':
      'To be eligible for the $249 Suvie offer, you must order 4 Smart Meal boxes within 6 months of receiving your Suvie. If you return your Suvie in the 100 Day Trial period you’ll get your money back and owe nothing else. If you keep your Suvie and don’t order any boxes, you will be charged $80 for each box that was not ordered up to a maximum of $320.'
  },
  S201_ACEP202: {
    '4mealboxpurchase':
      'To be eligible for the $349 Suvie offer, you must order 4 Smart Meal boxes within 6 months of receiving your Suvie. If you return your Suvie in the 100 Day Trial period you’ll get your money back and owe nothing else. If you keep your Suvie and don’t order any boxes, you will be charged $80 for each box that was not ordered up to a maximum of $320.'
  },
  S201_ACEP202C: {
    '4mealboxpurchase':
      'To be eligible for the $249 or $349 Suvie offer, you must order 4 Smart Meal boxes within 6 months of receiving your Suvie. If you return your Suvie in the 100 Day Trial period you’ll get your money back and owe nothing else. If you keep your Suvie and don’t order any boxes, you will be charged $80 for each box that was not ordered up to a maximum of $320.'
  },
  SUVIE201R: {
    '2mealboxrequirement':
      'To be eligible for the $349 Suvie offer, you must order 2 Smart Meal boxes within 6 months of receiving your Suvie. If you return your Suvie in the 100 Day Trial period you’ll get your money back and owe nothing else. If you keep your Suvie and don’t order any boxes, you will be charged $80 for each box that was not ordered up to a maximum of $160.'
  },
  SUVIE201RB: {
    '4mealboxpurchase':
      'To be eligible for the $299 Suvie offer, you must order 4 Suvie Meal boxes within 6 months of receiving your Suvie. If you return your Suvie in the 100 Day Trial period you’ll get your money back and owe nothing else. If you keep your Suvie and don’t order any boxes, you will be charged $80 for each box that was not ordered up to a maximum of $320.'
  },
  S201_ACEP202R: {
    '4mealboxpurchase':
      'To be eligible for the $399 Suvie offer, you must order 4 Suvie Meal boxes within 6 months of receiving your Suvie. If you return your Suvie in the 100 Day Trial period you’ll get your money back and owe nothing else. If you keep your Suvie and don’t order any boxes, you will be charged $80 for each box that was not ordered up to a maximum of $320.'
  }
}
