/* eslint-disable no-use-before-define */
import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { combineReducers, PreloadedState } from 'redux'

import { IS_DEV } from '@/configs/env'
import {
  accountPlanSettingsReducer,
  accountReducer,
  allergensMealsFilterReducer,
  appliancesFilterReducer,
  checkoutReducer,
  cuisinesMealsFilterReducer,
  dietaryPreferencesFilterReducer,
  loadingReducer,
  modalsReducer,
  orderReducer,
  proteinsMealsFilterReducer,
  sortMealsByReducer,
  tradeUpCheckoutReducer,
  uiReducer
} from '@/store/slices'

const rootReducer = combineReducers({
  ui: uiReducer,
  cuisinesMealsFilter: cuisinesMealsFilterReducer,
  proteinsMealsFilter: proteinsMealsFilterReducer,
  allergensMealsFilter: allergensMealsFilterReducer,
  dietaryPreferencesFilter: dietaryPreferencesFilterReducer,
  sortMealsBy: sortMealsByReducer,
  appliancesFilter: appliancesFilterReducer,
  account: accountReducer,
  order: orderReducer,
  accountPlanSettings: accountPlanSettingsReducer,
  loading: loadingReducer,
  modals: modalsReducer,
  checkout: checkoutReducer,
  tradeUpCheckout: tradeUpCheckoutReducer
})

export const createStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: IS_DEV
  })

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof createStore>
export type AppDispatch = AppStore['dispatch']

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
