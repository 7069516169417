import { IApplianceBatch, IUnnormalizedApplianceBatch } from '@/types'

export const normalizeApplianceBatch = (
  applianceBatch: IUnnormalizedApplianceBatch
): IApplianceBatch => ({
  id: applianceBatch?.id,
  name: applianceBatch?.name,
  status: applianceBatch?.status,
  variant: {
    id: applianceBatch?.variant?.id ?? null,
    name: applianceBatch?.variant?.name ?? null,
    sku: applianceBatch?.variant?.sku ?? null,
    isMaster: applianceBatch?.variant?.is_master ?? false,
    variantName: applianceBatch?.variant?.variant_name ?? null,
    blobs:
      applianceBatch?.variant?.blobs?.map((blob) => ({
        blobId: blob?.blob_id ?? null,
        contentType: blob?.content_type ?? null
      })) ?? [],
    product: {
      id: applianceBatch?.variant?.product?.id ?? null,
      name: applianceBatch?.variant?.product?.name ?? null,
      description: applianceBatch?.variant?.product?.description ?? null,
      properties:
        applianceBatch?.variant?.product?.properties?.map((property) => ({
          property: {
            name: property?.property?.name ?? null
          },
          value: property?.value ?? null
        })) ?? []
    }
  }
})
