import { createSlice } from '@reduxjs/toolkit'

import { IAccount, IStoreState } from '@/types'

export interface IAccountState {
  value?: IAccount
}

const initialState: IAccountState = {
  value: null
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    set: (state, action: { payload: IAccount }) => {
      state.value = action.payload
    }
  }
})

export const { set } = accountSlice.actions

export const accountSelector = (state: IStoreState): IAccount =>
  state.account.value

export default accountSlice.reducer
