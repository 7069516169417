import '../styles/globals.scss'

import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'

import AppApolloProvider from '@/components/AppApolloProvider'
import AppReduxProvider from '@/components/AppReduxProvider'
import SiteProgressBar from '@/components/SiteProgressBar'
import { IS_PROD } from '@/configs/env'
import { META_DEFAULTS } from '@/configs/misc'
import { useInspectlet, useNorthbeamIdentify } from '@/hooks'

const AppModals = dynamic(() => import('@/components/AppModals'), {
  ssr: false
})

const OfflineOverlay = dynamic(() => import('@/components/OfflineOverlay'), {
  ssr: false
})

const ToastNotification = dynamic(
  () => import('@/components/ToastNotification'),
  { ssr: false }
)

const GoogleTagManagerScript = dynamic(
  () => import('@/components/GoogleTagManagerScript'),
  { ssr: false }
)

const AppPolyfills = dynamic(() => import('@/components/AppPolyfills'), {
  ssr: false
})

const App = ({ Component, pageProps }: AppProps) => {
  useInspectlet(pageProps?.account?.email)
  useNorthbeamIdentify(pageProps?.account?.email)

  return (
    <>
      <Head>
        <title>{`${META_DEFAULTS.title} | ${META_DEFAULTS.description}`}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
        />
      </Head>

      {IS_PROD && <GoogleTagManagerScript />}

      <SiteProgressBar />

      <AppApolloProvider
        session={pageProps?.session}
        initialApolloState={pageProps?.initialApolloState}
      >
        <AppReduxProvider account={pageProps?.account}>
          <ToastNotification />
          <OfflineOverlay />
          <AppModals />
          <Component {...pageProps} />
        </AppReduxProvider>
      </AppApolloProvider>

      <AppPolyfills />
    </>
  )
}

export default App
