export enum EMealCategoryKey {
  SOUSE_VIDE_GEN_2 = 'sous-vide-gen-2',
  PROTEIN_PLUS = 'protein-plus',
  PROTEIN = 'protein',
  FOUR_NEWEST_MEALS = '4-newest-meals',
  SLOW_COOK_GEN_1 = 'slow-cook-gen-1',
  SAUCES = 'sauces',
  SIDES = 'sides',
  DESSERT = 'dessert',
  BREAKFAST = 'breakfast',
  SLOW_COOK = 'slow-cook',
  RAPID_COOK_GEN_2 = 'rapid-cook-gen-2',
  SOUSE_VIDE = 'sous-vide',
  SOUSE_VIDE_MEALS = 'sous-vide-meals',
  SUVIE_MEALS = 'suvie-meals'
}
