import maxBy from 'lodash/maxBy'

import {
  ISpreeOrderLineItem,
  ISpreeProductVariant,
  ISpreeRelatedProduct,
  IUnnormalizedSpreeOrderLineItem
} from '@/types'
import { convertStringPriceToCents } from '@/utils/misc'
import { getIsWarranty, getOriginalPriceCents } from '@/utils/spreeProducts'

export const normalizeSpreeOrderLineItem = (
  spreeOrderLineItem: IUnnormalizedSpreeOrderLineItem
): ISpreeOrderLineItem => ({
  id: spreeOrderLineItem?.id,
  price: convertStringPriceToCents(spreeOrderLineItem?.price),
  quantity: spreeOrderLineItem?.quantity
    ? parseInt(spreeOrderLineItem?.quantity)
    : 0,
  variantId: spreeOrderLineItem?.variant_id,
  variant: {
    id: spreeOrderLineItem?.variant?.id ?? null,
    name: spreeOrderLineItem?.variant?.name ?? null,
    description: spreeOrderLineItem?.variant?.description ?? null,
    sku: spreeOrderLineItem?.variant?.sku ?? null,
    isMaster: spreeOrderLineItem?.variant?.is_master ?? false,
    variantName: spreeOrderLineItem?.variant?.variant_name ?? null,
    blobs:
      spreeOrderLineItem?.variant?.blobs?.map((blob) => ({
        blobId: blob?.blob_id ?? null,
        contentType: blob?.content_type ?? null
      })) ?? [],
    product: {
      id: spreeOrderLineItem?.variant?.product?.id ?? null,
      name: spreeOrderLineItem?.variant?.product?.name ?? null,
      description: spreeOrderLineItem?.variant?.product?.description ?? null,
      properties:
        spreeOrderLineItem?.variant?.product?.properties?.map((property) => ({
          property: {
            name: property?.property?.name ?? null
          },
          value: property?.value ?? null
        })) ?? []
    }
  }
})

export const normalizeSpreeOrderLineItems = (
  spreeOrderLineItems: IUnnormalizedSpreeOrderLineItem[]
): ISpreeOrderLineItem[] =>
  spreeOrderLineItems?.map((spreeOrderLineItem) =>
    normalizeSpreeOrderLineItem(spreeOrderLineItem)
  )

export const getIsVariantExistedInLineItems = (
  lineItems: ISpreeOrderLineItem[],
  variantId: number
) => lineItems?.some((lineItem) => lineItem?.variantId === variantId)

export const getSelectedAddonVariants = (
  spreeLineItems: ISpreeOrderLineItem[],
  addonItems: ISpreeRelatedProduct[]
) =>
  addonItems
    ?.map((addonItem) => {
      const variants = addonItem?.relatedProduct?.variants ?? []

      const nonMasterVariants = variants
        ?.filter((variant) => !variant?.isMaster)
        ?.sort((a, b) => a?.position - b?.position)

      const variant = nonMasterVariants?.[0] ?? variants?.[0]

      const isVariantSelected = spreeLineItems?.find(
        (lineItem) => lineItem?.variant?.id === variant?.id
      )

      if (isVariantSelected) {
        const name = addonItem?.relatedProduct?.name
        const description = addonItem?.relatedProduct?.description

        return {
          ...variant,
          parentName: name,
          parentDescription:
            variants?.length > 1 ? variant.variantName : description
        }
      }

      return null
    })
    .filter(Boolean)

export const getMoreExpensiveLineItem = (
  lineItems: ISpreeOrderLineItem[]
): ISpreeOrderLineItem => maxBy(lineItems, (lineItem) => lineItem?.price)

export const getOrderDiscountCentsFromLineItems = (
  lineItems: ISpreeOrderLineItem[]
): number => {
  const mainLineItem = getMoreExpensiveLineItem(lineItems)
  const originalPriceCents = getOriginalPriceCents(
    mainLineItem?.variant?.product
  )
  const currentPriceCents = (mainLineItem?.price ?? 0) * 100

  return originalPriceCents - currentPriceCents
}

export const getLineItemName = (lineItem: ISpreeOrderLineItem): string | null =>
  lineItem?.variant?.product?.name ?? lineItem?.variant?.name ?? null

export const getLineItemDescription = (
  lineItem: ISpreeOrderLineItem
): string | null =>
  lineItem?.variant?.description ??
  lineItem?.variant?.product?.description ??
  lineItem?.variant?.variantName ??
  null

export const getAdditionalSpreeLineItems = (
  lineItems: ISpreeOrderLineItem[],
  variant: ISpreeProductVariant
): { variantId: number; quantity: number }[] =>
  getIsVariantExistedInLineItems(lineItems, variant?.id)
    ? []
    : [{ variantId: variant?.id, quantity: 1 }]

export const getWarrantySpreeLineItems = (
  lineItems: ISpreeOrderLineItem[]
): ISpreeOrderLineItem[] =>
  lineItems?.filter((lineItem) => getIsWarranty(lineItem?.variant?.product))
