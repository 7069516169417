import { DEFAULT_REFERRAL_SKU } from '@/configs/referral'
import {
  ESpreeOrderShipmentState,
  ISpreeOrder,
  ISpreeOrderLineItem,
  IUnnormalizedSpreeOrder
} from '@/types'
import { convertStringPriceToCents } from '@/utils/misc'
import { normalizeSpreeAddress } from '@/utils/spreeAddress'
import { normalizeSpreeOrderLineItems } from '@/utils/spreeOrderLineItem'
import { normalizeSpreeShipments } from '@/utils/spreeShipments'

export const normalizeSpreeOrder = (
  spreeOrder: IUnnormalizedSpreeOrder
): ISpreeOrder => ({
  id: spreeOrder?.id,
  number: spreeOrder?.number,
  state: spreeOrder?.state,
  shipmentState: spreeOrder?.shipment_state,
  paymentState: spreeOrder?.payment_state,
  mealTotals: {
    itemTotal: spreeOrder?.meal_totals?.item_total,
    shipmentTotal: spreeOrder?.meal_totals?.shipment_total,
    mealItemTotal: spreeOrder?.meal_totals?.meal_item_total,
    addonItemTotal: spreeOrder?.meal_totals?.addon_item_total,
    adjustmentTotal: spreeOrder?.meal_totals?.adjustment_total,
    total: spreeOrder?.meal_totals?.total
  },
  shipTotal: convertStringPriceToCents(spreeOrder?.ship_total),
  shipmentTotal: convertStringPriceToCents(spreeOrder?.shipment_total),
  itemTotal: convertStringPriceToCents(spreeOrder?.item_total),
  taxTotal: convertStringPriceToCents(spreeOrder?.tax_total),
  additionalTaxTotal: convertStringPriceToCents(
    spreeOrder?.additional_tax_total
  ),
  includedTaxTotal: convertStringPriceToCents(spreeOrder?.included_tax_total),
  total: convertStringPriceToCents(spreeOrder?.total),
  adjustmentTotal: convertStringPriceToCents(spreeOrder?.adjustment_total),
  promoTotal: convertStringPriceToCents(spreeOrder?.promo_total),
  shippingBalance: convertStringPriceToCents(spreeOrder?.shipping_balance),
  discountTotal: convertStringPriceToCents(spreeOrder?.discount_total),
  token: spreeOrder?.token,
  userId: spreeOrder?.user_id,
  completedAt: spreeOrder?.completed_at,
  lineItems: normalizeSpreeOrderLineItems(spreeOrder?.line_items ?? []),
  billAddress: normalizeSpreeAddress(spreeOrder?.bill_address),
  shipAddress: normalizeSpreeAddress(spreeOrder?.ship_address),
  shipments: normalizeSpreeShipments(spreeOrder?.shipments)
})

export const getHasOrderShipmentStatePending = (
  spreeOrder: ISpreeOrder
): boolean => spreeOrder?.shipmentState === ESpreeOrderShipmentState.PENDING

export const getHasOrderShipmentStateReady = (
  spreeOrder: ISpreeOrder
): boolean => spreeOrder?.shipmentState === ESpreeOrderShipmentState.READY

export const getHasOrderShipmentStateShipped = (
  spreeOrder: ISpreeOrder
): boolean => spreeOrder?.shipmentState === ESpreeOrderShipmentState.SHIPPED

export const getHasOrderShipmentStateCanceled = (
  spreeOrder: ISpreeOrder
): boolean => spreeOrder?.shipmentState === ESpreeOrderShipmentState.CANCELED

export const findReferralProgramLineItem = (
  spreeOrder: ISpreeOrder
): ISpreeOrderLineItem =>
  spreeOrder?.lineItems?.find(
    (lineItem) => lineItem?.variant?.sku === DEFAULT_REFERRAL_SKU
  )
