import { createSlice } from '@reduxjs/toolkit'

import { ISpreeReferralInvite } from '@/types'

export interface IModalsState {
  suvieMealSubscriptionModal: {
    isVisible: boolean
  }
  tradeUpOfferModal: {
    isVisible: boolean
  }
  holidayReminderModal: {
    deliveryDate: string | null
    isVisible: boolean
  }
  referralModal: {
    isVisible: boolean
  }
  referralInviteSentModal: {
    spreeReferralInvite: ISpreeReferralInvite | null
    isVisible: boolean
  }
}

const initialState: IModalsState = {
  suvieMealSubscriptionModal: {
    isVisible: false
  },
  tradeUpOfferModal: {
    isVisible: false
  },
  holidayReminderModal: {
    deliveryDate: null,
    isVisible: false
  },
  referralModal: {
    isVisible: false
  },
  referralInviteSentModal: {
    spreeReferralInvite: null,
    isVisible: false
  }
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openSuvieMealSubscriptionModal: (state) => {
      state.suvieMealSubscriptionModal = {
        isVisible: true
      }
    },
    closeSuvieMealSubscriptionModal: (state) => {
      state.suvieMealSubscriptionModal = {
        isVisible: false
      }
    },
    toggleSuvieMealSubscriptionModal: (state) => {
      state.suvieMealSubscriptionModal = {
        isVisible: !state.suvieMealSubscriptionModal.isVisible
      }
    },
    openSuvieTradeUpOfferModal: (state) => {
      state.tradeUpOfferModal = {
        isVisible: true
      }
    },
    closeSuvieTradeUpOfferModal: (state) => {
      state.tradeUpOfferModal = {
        isVisible: false
      }
    },
    toggleSuvieTradeUpOfferModal: (state) => {
      state.tradeUpOfferModal = {
        isVisible: !state.tradeUpOfferModal.isVisible
      }
    },
    openHolidayReminderModal: (
      state,
      action: { payload: { deliveryDate: string } }
    ) => {
      state.holidayReminderModal = {
        deliveryDate: action.payload.deliveryDate,
        isVisible: true
      }
    },
    closeHolidayReminderModal: (state) => {
      state.holidayReminderModal = {
        deliveryDate: null,
        isVisible: false
      }
    },
    openReferralModal: (state) => {
      state.referralModal = {
        isVisible: true
      }
    },
    closeReferralModal: (state) => {
      state.referralModal = {
        isVisible: false
      }
    },
    openReferralInviteSentModal: (
      state,
      action: { payload: { spreeReferralInvite: ISpreeReferralInvite } }
    ) => {
      state.referralInviteSentModal = {
        spreeReferralInvite: action.payload.spreeReferralInvite,
        isVisible: true
      }
    },
    closeReferralInviteSentModal: (state) => {
      state.referralInviteSentModal = {
        spreeReferralInvite: null,
        isVisible: false
      }
    }
  }
})

export const {
  openSuvieMealSubscriptionModal,
  closeSuvieMealSubscriptionModal,
  toggleSuvieMealSubscriptionModal,
  openSuvieTradeUpOfferModal,
  closeSuvieTradeUpOfferModal,
  toggleSuvieTradeUpOfferModal,
  openHolidayReminderModal,
  closeHolidayReminderModal,
  openReferralModal,
  closeReferralModal,
  openReferralInviteSentModal,
  closeReferralInviteSentModal
} = modalsSlice.actions

export const suvieMealSubscriptionModalSelector = (state) =>
  state.modals.suvieMealSubscriptionModal
export const tradeUpOfferModalSelector = (state) =>
  state.modals.tradeUpOfferModal
export const holidayReminderModalSelector = (state) =>
  state.modals.holidayReminderModal
export const referralModalSelector = (state) => state.modals.referralModal
export const referralInviteSentModalSelector = (state) =>
  state.modals.referralInviteSentModal

export default modalsSlice.reducer
