import { createSlice } from '@reduxjs/toolkit'

import { IStoreState, TMealsSortOptions } from '@/types'

export interface ISortMealsByState {
  value: TMealsSortOptions
}

const initialState: ISortMealsByState = {
  value: 'Popular'
}

export const sortMealsBySlice = createSlice({
  name: 'sortMealsBy',
  initialState,
  reducers: {
    update: (state, action: { payload: TMealsSortOptions }) => {
      state.value = action.payload
    }
  }
})

export const { update } = sortMealsBySlice.actions

export const sortMealsBySelector = (state: IStoreState): TMealsSortOptions =>
  state.sortMealsBy.value

export default sortMealsBySlice.reducer
