import { IMealOrder, IUnnormalizedMealOrder } from '@/types'
import {
  getLargeImageSrc,
  getMediumImageSrc,
  getThumbnailImageSrc
} from '@/utils/images'
import { normalizeMealCategory } from '@/utils/mealCategories'

export const normalizeMealOrder = (
  mealOrder: IUnnormalizedMealOrder
): IMealOrder => ({
  id: Number(mealOrder?.id) ?? null,
  mealId: mealOrder?.meal_id ?? null,
  orderId: mealOrder?.order_id ?? null,
  quantity: mealOrder?.quantity ?? null,
  sauceSubstitutionSku: mealOrder?.sauce_substitution_sku ?? null,
  meal: {
    id: mealOrder?.meal?.attributes?.id ?? null,
    name: mealOrder?.meal?.attributes?.name ?? null,
    categories:
      mealOrder?.meal?.attributes?.categories?.map((category) =>
        normalizeMealCategory(category)
      ) ?? [],
    images: {
      thumb: getThumbnailImageSrc(mealOrder?.meal?.attributes?.images?.thumb),
      medium: getMediumImageSrc(mealOrder?.meal?.attributes?.images?.medium),
      large: getLargeImageSrc(mealOrder?.meal?.attributes?.images?.large)
    },
    isPremium: mealOrder?.meal?.attributes?.is_premium ?? false,
    familySizeAdjustments:
      mealOrder?.meal?.attributes?.family_size_adjustments?.map(
        (familySizeAdjustment) => ({
          adjustmentType:
            familySizeAdjustment?.attributes?.adjustment_type ?? null,
          familySize: familySizeAdjustment?.attributes?.family_size ?? null,
          value: familySizeAdjustment?.attributes?.value ?? null
        })
      ),
    variants: mealOrder?.meal?.attributes?.variants
      ? Object.fromEntries(
          Object.entries(mealOrder?.meal?.attributes?.variants).map(
            ([key, value]) => [
              key,
              {
                itemPriceCents: value.item_price_cents ?? null,
                premiumPerServingCents: value.premium_per_serving_cents ?? null,
                pricePerServingCents: value.price_per_serving_cents ?? null
              }
            ]
          )
        )
      : null
  }
})

export const sortMealOrdersById = (mealOrders: IMealOrder[]): IMealOrder[] =>
  mealOrders?.slice()?.sort((a, b) => a?.mealId - b?.mealId)
