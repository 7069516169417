import Router from 'next/router'
import NProgress from 'nprogress'
import { memo } from 'react'

const SiteProgressBar = () => {
  const template =
    '<div class="progress" role="bar"><div class="progress__peg"></div></div>'

  NProgress.configure({ showSpinner: false, template: template })

  Router.events.on('routeChangeStart', () => NProgress.start())
  Router.events.on('routeChangeComplete', () => NProgress.done())
  Router.events.on('routeChangeError', () => NProgress.done())

  return null
}

export default memo(SiteProgressBar)
