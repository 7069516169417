import { createSlice } from '@reduxjs/toolkit'

import { IStoreState, TApplianceType } from '@/types'

export interface IAppliancesFilterState {
  value: TApplianceType[]
}

const initialState: IAppliancesFilterState = {
  value: []
}

export const appliancesFilterSlice = createSlice({
  name: 'appliancesFilter',
  initialState,
  reducers: {
    addOrRemove: (state, action: { payload: TApplianceType }) => {
      state.value = state.value.includes(action.payload)
        ? state.value.filter((item) => item !== action.payload)
        : [...state.value, action.payload]
    },
    clear: (state) => {
      state.value = []
    }
  }
})

export const { addOrRemove, clear } = appliancesFilterSlice.actions

export const appliancesFilterSelector = (
  state: IStoreState
): TApplianceType[] => state.appliancesFilter.value

export default appliancesFilterSlice.reducer
