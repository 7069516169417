import { MAX_NUMBER_OF_ITEMS_IN_CART } from '@/configs/order'

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong'
export const SIGN_IN_ERROR_MESSAGE = 'Invalid email or password!'
export const MAGIC_SIGN_IN_ERROR_MESSAGE = 'Invalid token!'
export const REQUIRED_MESSAGE = 'Required'
export const INVALID_EMAIL_MESSAGE = 'Invalid email!'
export const MAX_LENGTH_MESSAGE = (max: number) => `Maximum length is ${max}!`
export const MIN_LENGTH_MESSAGE = (min: number) => `Minimum length is ${min}!`
export const INCORRECT_ZIP_CODE_MESSAGE = 'must contain only 5 digits'
export const INCORRECT_PHONE_MESSAGE = 'must be a valid phone'
export const SUCCESS_SKIPPED_ORDER_MESSAGE = 'Your delivery was skipped!'
export const SUCCESS_UNSKIPPED_ORDER_MESSAGE = 'You delivery was unskipped!'
export const SUCCESS_SAVE_PAYMENT_DETAILS_MESSAGE =
  'Your payment information was saved!'
export const SUCCESS_SAVE_DELIVERY_DETAILS_MESSAGE =
  'Your delivery information was saved!'
export const SUCCESS_UPDATE_SHIPPING_ADDRESS_MESSAGE =
  'Your shipping address was updated!'
export const SUCCESS_SAVE_ACCOUNT_DETAILS_MESSAGE =
  'Your account was successfully updated!'
export const SUCCESS_SAVE_ACCOUNT_PLAN_MESSAGE =
  'Your plan was successfully updated!'
export const SUCCESS_SAVE_MEAL_PREFERENCES_MESSAGE =
  'Your Meal Preferences were successfully updated. It is an ongoing change that affects the meals you see in a Planner.'
export const MAX_NUMBER_OF_ITEMS_IN_CART_MESSAGE = `You cannot add more than ${MAX_NUMBER_OF_ITEMS_IN_CART} items.`
export const SUCCESS_PAUSE_ACCOUNT_PLAN_MESSAGE =
  'Your Account has been paused.'
export const SUCCESS_RESET_PASSWORD_MESSAGE =
  'If a matching account was found an email was sent to allow you to reset your password.'
export const SUCCESS_GENERATE_MAGIC_LOGIN_TOKEN_MESSAGE =
  'If a matching account was found an email was sent to allow you to sign in.'
