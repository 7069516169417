import { createSlice } from '@reduxjs/toolkit'

import { IFilterItem, IStoreState } from '@/types'

export interface IProteinsMealsFilterState {
  value: IFilterItem[]
}

const initialState: IProteinsMealsFilterState = {
  value: []
}

export const proteinsMealsFilterSlice = createSlice({
  name: 'proteinsMealsFilter',
  initialState,
  reducers: {
    remove: (state, action: { payload: IFilterItem }) => {
      state.value = state.value.filter((item) => item.id !== action.payload.id)
    },
    update: (state, action: { payload: IFilterItem[] }) => {
      state.value = action.payload
    },
    clear: (state) => {
      state.value = []
    }
  }
})

export const { remove, update, clear } = proteinsMealsFilterSlice.actions

export const proteinsMealsFilterSelector = (
  state: IStoreState
): IFilterItem[] => state.proteinsMealsFilter.value

export default proteinsMealsFilterSlice.reducer
