import { ISpreeAddress, IUnnormalizedSpreeAddress } from '@/types'

export const normalizeSpreeAddress = (
  address: IUnnormalizedSpreeAddress
): ISpreeAddress => ({
  address1: address?.address1 ?? null,
  address2: address?.address2 ?? null,
  alternativePhone: address?.alternative_phone ?? null,
  city: address?.city ?? null,
  countryId: address?.country_id ?? null,
  id: address?.id ?? null,
  name: address?.name ?? null,
  phone: address?.phone ?? null,
  stateId: address?.state_id ?? null,
  zipcode: address?.zipcode ?? null
})
