import { DEFAULT_CURRENCY } from '@/configs/purchase'
import {
  ISpreeProduct,
  ISpreeProductVariant,
  ISpreeRelatedProduct,
  TApplianceType
} from '@/types'
import { getCustomSizeImageSrcByBlob } from '@/utils/images'
import { convertStringPriceToCents } from '@/utils/misc'

export const getMasterVariant = (
  product: ISpreeProduct
): ISpreeProductVariant | undefined =>
  product?.variants?.find((variant) => variant?.isMaster)

export const getMasterRelatedProductVariant = (
  relatedProduct: ISpreeRelatedProduct
): ISpreeProductVariant | undefined =>
  relatedProduct?.relatedProduct?.variants?.find((variant) => variant?.isMaster)

export const getVariantPriceCents = (variant: ISpreeProductVariant): number =>
  (variant?.prices?.[0]?.amount ?? 0) * 100

export const getVariantsPriceCents = (
  variants: ISpreeProductVariant[]
): number =>
  variants?.reduce((result, item) => result + getVariantPriceCents(item), 0)

export const getVariantPrice = (variant: ISpreeProductVariant): string =>
  (variant?.prices?.[0]?.amount ?? 0).toLocaleString('en-US', {
    style: 'currency',
    currency: DEFAULT_CURRENCY,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

export const getVariantImageSrc = (
  variant: ISpreeProductVariant
): string | null =>
  getCustomSizeImageSrcByBlob(
    variant?.blobs?.[0]?.blobId,
    variant?.blobs?.[0]?.contentType,
    128,
    128
  )

export const getApplianceType = (product: ISpreeProduct): TApplianceType =>
  product?.properties?.find(
    (property) => property?.property?.name === 'appliance-type-key'
  )?.value

export const getOriginalPriceCents = (product: ISpreeProduct): number => {
  const originalPriceValue = product?.properties?.find(
    (property) => property?.property?.name === 'original-price'
  )?.value

  return convertStringPriceToCents(originalPriceValue)
}

export const getEstimatedShippingTime = (product: ISpreeProduct): string => {
  const estimatedShippingTime = product?.properties?.find(
    (property) => property?.property?.name === 'estimated-shipping-time'
  )?.value

  return estimatedShippingTime ?? ''
}

export const getIsRefurbished = (product: ISpreeProduct): boolean =>
  product?.properties?.find(
    (property) => property?.property?.name === 'refurbished'
  )?.value === 'true'

export const getIsMealPlanSkippable = (product: ISpreeProduct): boolean =>
  product?.properties?.find(
    (property) => property?.property?.name === 'can_skip_meal_plan'
  )?.value === 'true'

export const getSavedAmountFromProperty = (product: ISpreeProduct): number => {
  const savedAmount = product?.properties?.find(
    (property) => property?.property?.name === 'saved_amount'
  )?.value

  return convertStringPriceToCents(savedAmount)
}

export const getIsDiscountTotalCentsRelated = (
  product: ISpreeProduct
): boolean =>
  product?.properties?.find(
    (property) => property?.property?.name === 'show_discount_value'
  )?.value === 'true'

export const getIsWarranty = (product: ISpreeProduct): boolean =>
  product?.properties?.find(
    (property) => property?.property?.name === 'warranty'
  )?.value === 'true'

// When the product has the taxon `appliances/packages` set in the Solidus Admin,
// it means the product has extended warranty available.
export const getProductHasWarranty = (product: ISpreeProduct): boolean =>
  product?.taxons?.some(
    (taxon) => taxon.taxon.permalink === 'appliances/packages'
  )

export const getProductDiscountCentsRelatedToOriginalPrice = (
  product: ISpreeProduct,
  productVariant: ISpreeProductVariant
): number => {
  const originalPriceCents = getOriginalPriceCents(product)
  const variantPriceCents = getVariantPriceCents(productVariant)

  return originalPriceCents > variantPriceCents
    ? Math.abs(originalPriceCents - variantPriceCents)
    : 0
}

export const getProductDescription = (
  product?: ISpreeRelatedProduct | ISpreeProduct | ISpreeProductVariant
) => {
  if (!product) {
    return
  }

  let description

  if ('parentDescription' in product) {
    const productVar = product as ISpreeProductVariant
    description = productVar.parentDescription ?? productVar.product.description
    return description
  }

  if ('description' in product) {
    const spreeProduct = product as ISpreeProduct
    description = spreeProduct.description
  } else {
    const relatedProduct = product as ISpreeRelatedProduct
    description = relatedProduct?.relatedProduct?.description
  }

  return (description?.split(/\n/) ?? []).filter(Boolean)
}
