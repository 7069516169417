import { createSlice } from '@reduxjs/toolkit'

import { ILoading, IStoreState } from '@/types'

export interface ILoadingState {
  value: ILoading
}

const initialState: ILoadingState = {
  value: {
    isEditingMealsPreferences: false
  }
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setIsEditingMealsPreferences: (state, action: { payload: boolean }) => {
      state.value.isEditingMealsPreferences = action.payload
    }
  }
})

export const { setIsEditingMealsPreferences } = loadingSlice.actions

export const loadingSelector = (state: IStoreState): ILoading =>
  state.loading.value

export default loadingSlice.reducer
