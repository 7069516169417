import { PATHS } from '@/configs/paths'
import {
  EAccountStatus,
  EApplianceType,
  EAutoSelectedMeals,
  IAccount,
  TAutoSelectedMeals
} from '@/types'

export const getHasAccountPreRegisteredStatus = (account: IAccount): boolean =>
  account?.status === EAccountStatus.PRE_REGISTERED

export const getHasAccountRegisteredStatus = (account: IAccount): boolean =>
  account?.status === EAccountStatus.REGISTERED

export const getHasAccountPurchasedStatus = (account: IAccount): boolean =>
  account?.status === EAccountStatus.PURCHASED

export const getHasAccountInvitedStatus = (account: IAccount): boolean =>
  account?.status === EAccountStatus.INVITED

export const getHasAccountActiveStatus = (account: IAccount): boolean =>
  account?.status === EAccountStatus.ACTIVE

export const getHasNoAppliances = (account: IAccount): boolean =>
  account?.applianceTypes?.length === 0

export const getHasApplianceV1 = (account: IAccount): boolean =>
  account?.applianceTypes
    ?.map((applianceType) => applianceType?.applianceType?.key)
    ?.includes(EApplianceType.KITCHEN_ROBOT_V_1_0)

export const getHasApplianceV2 = (account: IAccount): boolean =>
  account?.applianceTypes
    ?.map((applianceType) => applianceType?.applianceType?.key)
    ?.includes(EApplianceType.KITCHEN_ROBOT_V_2_O)

export const getHasStarchCooker = (account: IAccount): boolean =>
  account?.applianceTypes
    ?.map((applianceType) => applianceType?.applianceType?.key)
    ?.includes(EApplianceType.STARCH_COOKER)

export const getIsAllowedToEnterCheckoutSuccessStep = (
  account: IAccount
): boolean =>
  getHasAccountActiveStatus(account) || getHasAccountInvitedStatus(account)

export const getIsAllowedToEnterSuccessPage = (account: IAccount): boolean =>
  getHasAccountPurchasedStatus(account) ||
  getHasAccountInvitedStatus(account) ||
  getHasAccountActiveStatus(account)

export const getIsAllowedToEnterOnboardingPages = (
  account: IAccount
): boolean => getHasAccountInvitedStatus(account)

export const getIsAllowedToEnterPlannerPage = (account: IAccount): boolean =>
  getHasAccountActiveStatus(account)

export const getIsAllowedToEnterDeliveriesPage = (account: IAccount): boolean =>
  getIsAllowedToEnterPlannerPage(account) && !account?.paused

export const getRedirectPath = (account: IAccount): string => {
  if (getHasAccountPurchasedStatus(account)) {
    return PATHS.SUCCESS
  }

  if (getHasAccountInvitedStatus(account)) {
    return PATHS.ONBOARDING.WELCOME
  }

  if (getHasAccountRegisteredStatus(account)) {
    return PATHS.PURCHASE
  }

  if (getHasAccountPreRegisteredStatus(account)) {
    return PATHS.CHECKOUT.START
  }

  return PATHS.BASE
}

export const maybeConvertAutoselectedMealsValueToString = (
  autoselectedMeals: TAutoSelectedMeals | string
): TAutoSelectedMeals => {
  if (typeof autoselectedMeals === 'string') {
    return autoselectedMeals
  }

  if (autoselectedMeals === 0) {
    return EAutoSelectedMeals.CHEF_SELECTED
  }

  if (autoselectedMeals === 1) {
    return EAutoSelectedMeals.REPEAT_LAST
  }
}
