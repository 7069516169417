import { createSlice } from '@reduxjs/toolkit'

import { IAccountPlanSettings, IStoreState } from '@/types'

export interface IAccountPlanSettingsState {
  value: IAccountPlanSettings
}

const initialState: IAccountPlanSettingsState = {
  value: {
    mealsPerDelivery: null,
    familySize: null,
    frequency: null
  }
}

export const accountPlanSettingsSlice = createSlice({
  name: 'accountPlanSettings',
  initialState,
  reducers: {
    update: (state, action: { payload: Partial<IAccountPlanSettings> }) => {
      state.value = {
        ...state.value,
        ...action.payload
      }
    }
  }
})

export const { update } = accountPlanSettingsSlice.actions

export const accountPlanSettingsSelector = (
  state: IStoreState
): IAccountPlanSettings => state.accountPlanSettings.value

export default accountPlanSettingsSlice.reducer
