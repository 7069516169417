import isEmpty from 'lodash/isEmpty'

import {
  EApplianceType,
  EDietaryPreferenceSlug,
  EMealCategoryKey,
  IAccount,
  IAddon,
  IApplianceType,
  IFilterItem,
  IMeal,
  IMealVariant,
  IOrder,
  IProteinCategory,
  IUnnormalizedApplianceRecipe,
  IUnnormalizedMeal,
  TApplianceType,
  TMealsSortOptions
} from '@/types'
import {
  getHasApplianceV1,
  getHasApplianceV2,
  getHasNoAppliances
} from '@/utils/accounts'
import {
  getCustomSizeImageSrc,
  getLargeImageSrc,
  getMediumImageSrc,
  getThumbnailImageSrc
} from '@/utils/images'
import { normalizeMealCategory } from '@/utils/mealCategories'

const getRecipesForEachApplianceType = (
  mealApplianceTypes: {
    recipe?: IUnnormalizedApplianceRecipe
    applianceType?: IApplianceType
  }[]
): Record<string, unknown> => {
  const extractedRecipes: Record<string, unknown> = {} as Record<
    string,
    unknown
  >

  mealApplianceTypes?.forEach((mealApplianceType) => {
    if (
      mealApplianceType?.applianceType?.key &&
      !isEmpty(mealApplianceType?.recipe)
    ) {
      extractedRecipes[mealApplianceType?.applianceType?.key as string] =
        mealApplianceType?.recipe
    }
  })

  return extractedRecipes
}

export const normalizeMeal = (meal: IUnnormalizedMeal): IMeal => ({
  id: meal?.id ?? null,
  name: meal?.name ?? null,
  subtitle: meal?.subtitle ?? null,
  description: meal?.description ?? null,
  images: {
    thumb: getThumbnailImageSrc(meal?.image) ?? null,
    medium: getMediumImageSrc(meal?.image) ?? null,
    large: getLargeImageSrc(meal?.image) ?? null
  },
  nutritionalInformationImage:
    getCustomSizeImageSrc(
      meal?.nutritionalInformationImage,
      360,
      null,
      'fit'
    ) ?? null,
  popular: meal?.popular ?? false,
  predictedCookTime: Math.ceil(meal?.predictedCookTime / 60) ?? null,
  calories: meal?.calories ?? 0,
  mealCuisineId: meal?.mealCuisineId ?? null,
  mealAllergens:
    meal?.mealAllergens?.map((allergen) => ({
      id: allergen?.allergen?.id ?? null,
      name: allergen?.allergen?.name ?? null
    })) ?? [],
  proteinCategories:
    meal?.mealProteinCategories?.map(
      (mealProteinCategory) => mealProteinCategory?.proteinCategory
    ) ?? [],
  mealComponents:
    meal?.mealComponents?.map(
      (mealComponent) => mealComponent?.mealComponent
    ) ?? [],
  categories:
    meal?.categories?.map((category) =>
      normalizeMealCategory(category?.category)
    ) ?? [],
  applianceTypes:
    meal?.applianceTypes?.map((applianceType) => ({
      id: applianceType?.applianceType?.id ?? null,
      key: applianceType?.applianceType?.key ?? null,
      name: applianceType?.applianceType?.name ?? null
    })) ?? [],
  dietaryPreferences:
    meal?.dietaryPreferences?.map(
      (dietaryPreference) => dietaryPreference?.dietaryPreference
    ) ?? [],
  recipes: getRecipesForEachApplianceType(meal?.applianceTypes),
  familySizeAdjustments: meal?.familySizeAdjustments ?? null,
  isPremium: meal?.isPremium ?? false,
  type: meal?.type ?? null,
  slowCook: meal?.slowCook ?? false,
  preparationTime: meal?.preparationTime
    ? Math.ceil(meal?.preparationTime / 60)
    : 0,
  addon: meal?.addon ?? false,
  sizeDescriptor: meal?.sizeDescriptor ?? null,
  sku: meal?.sku ?? null,
  customizable: meal?.customizable ?? false,
  variants: meal?.variants
    ? Object.fromEntries(
        Object.entries(meal?.variants).map(([key, value]) => [
          key,
          {
            itemPriceCents: value?.item_price_cents,
            premiumPerServingCents: value?.premium_per_serving_cents,
            pricePerServingCents: value?.price_per_serving_cents
          }
        ])
      )
    : null,
  ingredients: meal?.ingredients ?? null,
  proteinPlusSauce: meal?.proteinPlusSauce ?? false,
  recipeInstructionsLink: meal?.recipeInstructionsLink,
  createdAt: meal?.createdAt ?? null
})

export const normalizeMeals = (meals: IUnnormalizedMeal[]): IMeal[] =>
  meals.map((meal) => normalizeMeal(meal))

export const filterByCuisinesFilter = (
  meals: IMeal[],
  filters: IFilterItem[]
): IMeal[] =>
  filters.length > 0
    ? meals?.filter(
        (meal) =>
          meal?.addon ||
          !meal?.mealCuisineId ||
          filters?.some((item) => meal?.mealCuisineId === item.id)
      )
    : meals

export const filterByProteinsFilter = (
  meals: IMeal[],
  filters: IFilterItem[]
): IMeal[] =>
  filters.length > 0
    ? meals?.filter(
        (meal) =>
          meal?.categories?.some((category) => category?.addon) ||
          filters?.some((item) =>
            meal?.proteinCategories?.some(
              (proteinCategory) => proteinCategory?.slug === item?.slug
            )
          )
      )
    : meals

export const filterByDietaryPreferencesFilter = (
  meals: IMeal[],
  filters: IFilterItem[]
): IMeal[] =>
  filters.length > 0
    ? meals.filter((meal) =>
        meal?.dietaryPreferences?.some((dietaryPreference) =>
          filters?.map((item) => item.id)?.includes(dietaryPreference?.id)
        )
      )
    : meals

export const filterByAllergensFilter = (
  meals: IMeal[],
  filters: IFilterItem[]
): IMeal[] =>
  filters.length > 0
    ? meals.filter(
        (meal) =>
          !meal?.mealAllergens?.some((allergen) =>
            filters?.map((item) => item.id)?.includes(allergen.id)
          )
      )
    : meals

export const filterByMealExclusions = (
  meals: IMeal[],
  mealExclusions: IProteinCategory[]
): IMeal[] =>
  mealExclusions?.length > 0
    ? meals?.filter(
        (meal) =>
          !mealExclusions?.some((mealExclusion) =>
            meal?.proteinCategories?.some(
              (proteinCategory) => proteinCategory?.slug === mealExclusion.slug
            )
          )
      )
    : meals

export const getApplianceTypeKeys = (meal: IMeal): TApplianceType[] =>
  meal?.applianceTypes?.map((applianceType) => applianceType?.key)

export const filterByApplianceTypeForCheckout = (
  meals: IMeal[],
  applianceType: TApplianceType
): IMeal[] => {
  if (applianceType === EApplianceType.KITCHEN_ROBOT_V_2_O) {
    return meals?.filter((meal) => {
      const mealApplianceTypeKeys = getApplianceTypeKeys(meal)

      return (
        !mealApplianceTypeKeys?.includes(EApplianceType.KITCHEN_ROBOT_V_1_0) &&
        mealApplianceTypeKeys?.includes(EApplianceType.KITCHEN_ROBOT_V_2_O)
      )
    })
  }

  if (applianceType === EApplianceType.KITCHEN_ROBOT_V_3_O) {
    return meals?.filter((meal) => {
      const mealApplianceTypeKeys = getApplianceTypeKeys(meal)

      return (
        !mealApplianceTypeKeys?.includes(EApplianceType.KITCHEN_ROBOT_V_1_0) &&
        mealApplianceTypeKeys?.includes(EApplianceType.KITCHEN_ROBOT_V_3_O)
      )
    })
  }

  return meals?.filter((meal) =>
    meal?.applianceTypes?.some(
      (mealApplianceType) => mealApplianceType?.key === applianceType
    )
  )
}

/*
 *   - Exclude meals if they have starch and starch filter off, no matter they have SV1 or SV2
 *   - For SV1 & SV2, display meals if the meal includes the appliance type as normal
 */

export const filterByAppliancesTypes = (
  meals: IMeal[],
  appliancesTypesKeys: TApplianceType[]
): IMeal[] => {
  const isStarchCookerTypeSelected = appliancesTypesKeys?.includes(
    EApplianceType.STARCH_COOKER
  )

  return appliancesTypesKeys?.length > 0
    ? meals?.filter((meal) => {
        if (
          isStarchCookerTypeSelected &&
          meal?.applianceTypes
            ?.map((applianceType) => applianceType?.key)
            .includes(EApplianceType.STARCH_COOKER)
        ) {
          return false
        }

        return meal?.applianceTypes?.some(
          (applianceType) => !appliancesTypesKeys?.includes(applianceType?.key)
        )
      })
    : meals
}

export const sortMeals = (
  meals: IMeal[],
  sortBy: TMealsSortOptions
): IMeal[] => {
  switch (sortBy) {
    case 'Popular':
      return [
        ...meals?.filter((meal) => meal?.popular === true),
        ...meals?.filter((meal) => meal?.popular === false)
      ]
    case 'Newest':
      return meals
        ?.slice()
        ?.sort(
          (a, b) =>
            new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
        )
    case 'Lowest calories':
      return meals?.slice()?.sort((a, b) => a?.calories - b?.calories)
    case 'Fast preparation time':
      return meals
        ?.slice()
        ?.sort((a, b) => a?.preparationTime - b?.preparationTime)
    case 'Fast cook time':
      return meals
        ?.slice()
        ?.sort((a, b) => a?.predictedCookTime - b?.predictedCookTime)
    default:
      return meals
  }
}

export const filterAndSortMeals = (
  meals: IMeal[],
  selectedCuisinesMealsFilters: IFilterItem[],
  selectedProteinsMealsFilters: IFilterItem[],
  selectedDietaryPreferencesFilters: IFilterItem[],
  selectedAllergensMealsFilters: IFilterItem[],
  appliancesTypesKeys: TApplianceType[],
  mealExclusions: IProteinCategory[],
  sortMealsBy: TMealsSortOptions
): IMeal[] => {
  const filteredMealsBySelectedCuisines = filterByCuisinesFilter(
    meals,
    selectedCuisinesMealsFilters
  )

  const filteredMealsBySelectedProteins = filterByProteinsFilter(
    filteredMealsBySelectedCuisines,
    selectedProteinsMealsFilters
  )

  const filteredMealsByDietaryPreferences = filterByDietaryPreferencesFilter(
    filteredMealsBySelectedProteins,
    selectedDietaryPreferencesFilters
  )

  const filteredMealsBySelectedAllergens = filterByAllergensFilter(
    filteredMealsByDietaryPreferences,
    selectedAllergensMealsFilters
  )

  const filteredByAppliancesTypes = filterByAppliancesTypes(
    filteredMealsBySelectedAllergens,
    appliancesTypesKeys
  )

  const filteredByMealExclusions = filterByMealExclusions(
    filteredByAppliancesTypes,
    mealExclusions
  )

  return sortMeals(filteredByMealExclusions, sortMealsBy)
}

export const getServingsPerMeal = (
  meal: IMeal | IAddon,
  accountFamilySize: number
): number =>
  meal?.familySizeAdjustments?.find(
    (familySizeAdjustment) =>
      familySizeAdjustment?.familySize === accountFamilySize &&
      familySizeAdjustment?.adjustmentType === 'servings_adjustment'
  )?.value ?? 1

export const getCreditQuantity = (
  meal: IMeal | IAddon,
  accountFamilySize: number
): number =>
  meal?.familySizeAdjustments?.find(
    (familySizeAdjustment) =>
      familySizeAdjustment?.familySize === accountFamilySize &&
      familySizeAdjustment?.adjustmentType === 'credit_adjustment'
  )?.value ?? 1

export const getPrices = (
  meal: IMeal | IAddon,
  accountFamilySize: number
): IMealVariant => meal?.variants?.[accountFamilySize]

export const getIsNotPurchasable = (
  mealCategoryKey: EMealCategoryKey,
  applianceTypes: IApplianceType[],
  account: IAccount,
  currentApplianceType?: TApplianceType
): boolean => {
  const hasNoAppliances = getHasNoAppliances(account)

  if (hasNoAppliances && !currentApplianceType) {
    return [
      EMealCategoryKey.RAPID_COOK_GEN_2,
      EMealCategoryKey.SLOW_COOK_GEN_1,
      EMealCategoryKey.SOUSE_VIDE,
      EMealCategoryKey.SOUSE_VIDE_MEALS
    ].includes(mealCategoryKey)
  }

  const isPurchasableOnlyForApplianceV1 = getIsPurchasableOnlyForApplianceV1(
    applianceTypes,
    account
  )

  const isPurchasableOnlyForApplianceV2 = getIsPurchasableOnlyForApplianceV2(
    applianceTypes,
    account
  )

  return (
    (isPurchasableOnlyForApplianceV1 || isPurchasableOnlyForApplianceV2) &&
    !currentApplianceType
  )
}

export const getIsPurchasableOnlyForApplianceV1 = (
  applianceTypes: IApplianceType[],
  account: IAccount,
  currentApplianceType?: TApplianceType
): boolean => {
  const hasApplianceV1 = getHasApplianceV1(account)
  const applianceTypesKeys = applianceTypes?.map(
    (applianceType) => applianceType?.key
  )

  return (
    !hasApplianceV1 &&
    applianceTypesKeys?.length === 1 &&
    applianceTypesKeys?.includes(EApplianceType.KITCHEN_ROBOT_V_1_0) &&
    currentApplianceType !== EApplianceType.KITCHEN_ROBOT_V_1_0
  )
}

export const getIsPurchasableOnlyForApplianceV2 = (
  applianceTypes: IApplianceType[],
  account: IAccount,
  currentApplianceType?: TApplianceType
): boolean => {
  const hasApplianceV2 = getHasApplianceV2(account)
  const applianceTypesKeys = applianceTypes?.map(
    (applianceType) => applianceType?.key
  )

  return (
    !hasApplianceV2 &&
    applianceTypesKeys?.length === 1 &&
    applianceTypesKeys?.includes(EApplianceType.KITCHEN_ROBOT_V_2_O) &&
    currentApplianceType !== EApplianceType.KITCHEN_ROBOT_V_2_O
  )
}

export const getIsSomeAllergenExistedInMeal = (
  meal: IMeal,
  allergensIds: number[]
): boolean =>
  meal?.mealAllergens?.some((mealAllergen) =>
    allergensIds?.includes(mealAllergen?.id)
  )

export const getIsSomeAllergenExistedInMeals = (
  meals: IMeal[],
  allergensIds: number[]
): boolean =>
  meals?.some((meal) => getIsSomeAllergenExistedInMeal(meal, allergensIds))

export const getMealsWithAllergens = (
  meals: IMeal[],
  allergensIds: number[]
): IMeal[] =>
  meals?.filter((meal) => getIsSomeAllergenExistedInMeal(meal, allergensIds))

export const getMealsInCart = (meals: IMeal[], order: IOrder): IMeal[] =>
  meals?.filter(
    (meal) =>
      order?.mealOrders?.some((item) => item?.mealId === meal?.id) ||
      order?.addons?.some((item) => item?.addonId === meal?.id)
  )

export const checkIfMealHasDietaryPreference = (
  meal: IMeal,
  dietaryPreferenceSlug: EDietaryPreferenceSlug
): boolean =>
  meal?.dietaryPreferences?.some(
    (dietaryPreference) => dietaryPreference?.slug === dietaryPreferenceSlug
  )
