import { EXTERNAL_LINKS } from '@/configs/externalLinks'
import { ECheckoutStep } from '@/types'

export const PATHS = {
  BASE: '/',
  DELIVERIES: '/deliveries',
  SUCCESS: '/success',
  BASE_WITH_ID: '/[id]',
  ORDERS: '/orders/[id]',
  PURCHASE: '/purchase',
  CHARGE_AFFIRM_ORDER: '/charge-affirm-order',
  ACCOUNT: {
    INDEX: '/account',
    AUTO_SELECTED_MEALS: '/account/auto-selected-meals',
    MEAL_PREFERENCES: '/account/meal-preferences',
    PREMIUM_MEALS: '/account/premium-meals',
    DELIVERY_DETAILS: '/account/delivery-details',
    PAYMENT_DETAILS: '/account/payment-details',
    ACCOUNT_DETAILS: '/account/account-details',
    ORDER_HISTORY: '/account/order-history',
    COUPONS: '/account/coupons'
  },
  AUTH: {
    SIGNIN: '/auth/signin',
    SIGNOUT: '/auth/signout',
    RESET_PASSWORD: '/auth/reset-password',
    VERIFY_PASSWORD: '/auth/verify-password',
    MAGIC_SIGNIN: '/auth/magic-signin',
    CREATE_PERSISTANT: '/auth/create-persistant'
  },
  ONBOARDING: {
    WELCOME: '/onboarding/welcome',
    SERVINGS: '/onboarding/servings',
    SUBSCRIPTION: '/onboarding/subscription',
    PAYMENT_INFORMATION: '/onboarding/payment-information'
  },
  CHECKOUT: {
    START: '/checkout/start',
    MEAL_PLAN: '/checkout/meal-plan',
    CHECKOUT: '/checkout/checkout',
    SUCCESS: '/checkout/success',
    STEPS: {
      [ECheckoutStep.ADDONS]: '#addons-step',
      [ECheckoutStep.WARRANTY]: '#warranty-step',
      [ECheckoutStep.SHIPPING_AND_BILLING]: '#shipping-and-billing-step',
      [ECheckoutStep.PAYMENT]: '#payment-step'
    }
  },
  TRADE_UP: {
    START: '/trade-up/start',
    CHECKOUT: '/trade-up/checkout',
    SUCCESS: '/trade-up/success'
  },
  REFERRAL: '/referral'
}

export const ACCOUNT_MENUS = [
  [
    {
      title: 'My Plan',
      href: PATHS.ACCOUNT.INDEX
    },
    {
      title: 'Delivery Address',
      href: PATHS.ACCOUNT.DELIVERY_DETAILS
    },
    {
      title: 'Payment Details',
      href: PATHS.ACCOUNT.PAYMENT_DETAILS
    },
    {
      title: 'Account Details',
      href: PATHS.ACCOUNT.ACCOUNT_DETAILS
    }
  ],
  [
    {
      title: 'Order History',
      href: PATHS.ACCOUNT.ORDER_HISTORY
    },
    {
      title: 'Coupons',
      href: PATHS.ACCOUNT.COUPONS
    },
    {
      title: 'Referrals',
      href: PATHS.REFERRAL
    }
  ],
  [
    {
      title: 'Support',
      href: EXTERNAL_LINKS.HELP_DESK,
      external: true
    },
    {
      title: 'Guides',
      href: EXTERNAL_LINKS.BLOG,
      external: true
    },
    {
      title: 'Accessibility',
      href: EXTERNAL_LINKS.ACCESSIBILITY,
      external: true
    }
  ]
]
