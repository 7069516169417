import { ISpreeShipment, IUnnormalizedSpreeShipment } from '@/types'
import { normalizeApplianceBatch } from '@/utils/applianceBatches'

export const normalizeSpreeShipment = (
  shipment: IUnnormalizedSpreeShipment
): ISpreeShipment => ({
  id: shipment?.id ?? null,
  tracking: shipment?.tracking ?? null,
  trackingUrl: shipment?.tracking_url ?? null,
  number: shipment?.number ?? null,
  cost: shipment?.cost ?? null,
  shippedAt: shipment?.shipped_at ?? null,
  orderId: shipment?.order_id ?? null,
  state: shipment?.state ?? null,
  createdAt: shipment?.created_at ?? null,
  updatedAt: shipment?.updated_at ?? null,
  adjustmentTotal: shipment?.adjustment_total ?? null,
  additionalTaxTotal: shipment?.additional_tax_total ?? null,
  promoTotal: shipment?.promo_total ?? null,
  includedTaxTotal: shipment?.included_tax_total ?? null,
  refundStatus: shipment?.refund_status ?? null,
  applianceBatch: normalizeApplianceBatch(shipment?.applianceBatch)
})

export const normalizeSpreeShipments = (
  shipments: IUnnormalizedSpreeShipment[]
): ISpreeShipment[] =>
  shipments?.map((shipment) => normalizeSpreeShipment(shipment))

export const getShipmentsTotalCents = (shipments: ISpreeShipment[]): number =>
  shipments?.reduce(
    (cents, shipment) =>
      cents +
      (shipment?.cost ?? 0) * 100 +
      (shipment?.adjustmentTotal ?? 0) * 100,
    0
  )
