import { ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { ReactNode, useMemo } from 'react'

import { ISession } from '@/types'
import { initializeApolloClient } from '@/utils/apollo'

type AppApolloProviderProps = {
  children: ReactNode
  session?: ISession
  initialApolloState?: NormalizedCacheObject
}

export const AppApolloProvider = ({
  children,
  session,
  initialApolloState
}: AppApolloProviderProps) => {
  const apolloClient = useMemo(
    () =>
      initializeApolloClient({
        session,
        initialApolloState
      }),
    [session, initialApolloState]
  )

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AppApolloProvider
